import React, { Component } from "react";
import { AuthComponentProps } from "./AuthRoute.component";

export class Unauthorized extends Component<AuthComponentProps> {
  constructor(props: AuthComponentProps) {
    super(props);
    console.log("Unauthorized.constructor", this.props);
  }

  render() {
    return (
      <div>
        <h1>Sie sind nicht berechtigt diese Seite zu sehen</h1>
      </div>
    );
  }
}
