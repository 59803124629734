import React, { Component, ReactElement } from "react";
import { Link } from "react-router-dom";
import { AllianceMinimal } from "../views/alliance/Alliance.interface";

export interface AllianceLinkProps {
  alliance: AllianceMinimal;
}

export class AllianceLinkWithID extends Component<AllianceLinkProps> {
  render(): ReactElement {
    const alliance = this.props.alliance;
    const nameAndId = `${alliance.name} (${alliance.id})`;
    return (
      <Link
        className="full-hd:whitespace-nowrap"
        to={"/alliance/" + alliance.id}
        dangerouslySetInnerHTML={{ __html: nameAndId }}
      />
    );
  }
}

export class AllianceLink extends Component<AllianceLinkProps> {
  render(): ReactElement {
    return (
      <Link
        className="full-hd:whitespace-nowrap"
        to={"/alliance/" + this.props.alliance.id}
        dangerouslySetInnerHTML={{ __html: this.props.alliance.name }}
      />
    );
  }
}
