import React, { Component, FormEvent } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Api } from "../../api/Api";
import { AuthRoutes, CurrentUser, NonAuthRoutes } from "../App";
import { UserRoles } from "../../Globals";
import jwtDecode, { JwtPayload } from "jwt-decode";

interface TokenPayload extends JwtPayload {
  username: string;
  userId: number;
  roles: string[];
}

interface LoginProps extends RouteComponentProps {
  currentUser: CurrentUser;
}

export class Login extends Component<LoginProps> {
  state: {
    error: boolean;
    username: string;
    password: string;
  };

  constructor(props: LoginProps) {
    super(props);
    this.state = {
      error: false,
      username: "",
      password: "",
    };
  }

  onChange = (event: FormEvent<HTMLInputElement>) => {
    if (event.currentTarget.name === "username") {
      this.setState({ username: event.currentTarget.value });
    }
    if (event.currentTarget.name === "password") {
      this.setState({ password: event.currentTarget.value });
    }
  };

  onSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    console.log('Login clicked');
    let currentUser = this.props.currentUser;
    try {
      // Retrieve and parse token
      const api = Api.getInstance();
      api.setCredentials(this.state.username, this.state.password);
      const token = await api.fetchToken();
      const payload = jwtDecode<TokenPayload>(token);
      console.log("Token", token, payload);
      
      currentUser.name = this.state.username;
      currentUser.token = token;
      currentUser.roles = payload.roles.map((role) => {
        switch (role) {
          case "admin":
            return UserRoles.admin;
          case "user":
            return UserRoles.user;
          default:
            return UserRoles.none;
        }
      });
      currentUser.updateCurrentUser(currentUser);
      if (this.isLoginPage()) {
        this.props.history.push(AuthRoutes.welcome);
      }
    } catch (error) {
      console.error(error);
      this.setState({ error: true });
    }
  };

  isLoginPage(): boolean {
    return this.props.location.pathname === NonAuthRoutes.login;
  }

  render() {
    // console.log('Login.render:');
    return (
      <section className="flex flex-col max-w-full p-2 mt-8">
        <h1 className="flex flex-row items-center justify-center p-2 text-center bg-gray-800 border-4 border-gray-700 border-solid">
          <img
            className="w-8 h-8 mr-2 fill-current"
            src="/favicon.ico"
            alt="Logo"
          />
          <span>STC2-DB - Login</span>
        </h1>
        {!this.isLoginPage() && (
          <h2 className="p-2 mt-4 text-center bg-gray-800 border-4 border-red-800 border-solid">
            Die gewünschte Seite kann nicht angezeigt werden, da du nicht
            angemeldet bist!
          </h2>
        )}
        {this.state.error && (
          <h3 className="p-2 mt-4 text-center bg-gray-800 border-4 border-red-800 border-solid">
            Anmelden fehlgeschlagen!
          </h3>
        )}
        <form
          onSubmit={this.onSubmit}
          noValidate={true}
          className="grid grid-cols-2 gap-1 p-2 mx-auto mt-8 border-8 border-gray-700 border-solid rounded-lg"
        >
          <label className="w-32 px-2 bg-gray-800 border-2 border-gray-800 border-solid">
            Benutzername
          </label>
          <input
            className="w-32 px-2 text-left text-black bg-gray-700 border-2 border-gray-500 border-solid focus:border-orange-300"
            type="text"
            name="username"
            value={this.state.username}
            onChange={this.onChange}
          />
          <label className="w-32 px-2 bg-gray-800 border border-gray-800 border-solid">
            Passwort
          </label>
          <input
            className="w-32 px-2 text-left text-black bg-gray-700 border-2 border-gray-500 border-solid focus:border-orange-300"
            type="password"
            name="password"
            value={this.state.password}
            onChange={this.onChange}
          />
          <button
            type="submit"
            className="w-32 py-1 mt-1 bg-gray-800 border border-gray-500 border-solid rounded-md hover:border-orange-300 hover:text-orange-300"
          >
            Anmelden
          </button>
          <button
            type="reset"
            className="w-32 py-1 mt-1 bg-gray-800 border border-gray-500 border-solid rounded-md hover:border-orange-300 hover:text-orange-300"
          >
            Abbrechen
          </button>
        </form>
      </section>
    );
  }
}
