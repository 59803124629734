import React, { Component, ReactElement } from "react";
import { DateTimeSpan } from "../../components/DateSpan.component";
import {
  TDAllianceLinkWithID,
  TDName,
  TDRight,
  TH,
} from "../../components/Tables.component";
import { Player, PlayerHistory } from "./Player.interfaces";

export interface PlayerHistoryListProps {
  player: Player;
  histories: PlayerHistory[];
}

export class PlayerHistoryList extends Component<PlayerHistoryListProps> {
  render(): ReactElement {
    if (!this.props.player) {
      throw Error("Player not loaded");
    }
    if (!this.props.histories) {
      throw Error("Histories not loaded");
    }
    return (
      <table className="table border-separate">
        <thead className="">
          <tr className="">
            <TH>Name</TH>
            <TH>Ansehen</TH>
            <TH>Level</TH>
            <TH>Runden</TH>
            <TH>Allianz</TH>
            <TH>Wissenschaft</TH>
            <TH>Geselschaft</TH>
            <TH>Wirtschaft</TH>
            <TH>Militär</TH>
            <TH>Aktualisiert</TH>
            <TH>Erstellt / Überschrieben</TH>
          </tr>
        </thead>
        <tbody>
          <tr key="0">
            <TDName name={this.props.player.name} />
            <TDRight>{this.props.player.reputation}</TDRight>
            <TDRight>{this.props.player.level}</TDRight>
            <TDRight>{this.props.player.ticks}</TDRight>
            <TDAllianceLinkWithID alliance={this.props.player.alliance} />
            <TDRight>{this.props.player.points.research}</TDRight>
            <TDRight>{this.props.player.points.society}</TDRight>
            <TDRight>{this.props.player.points.economy}</TDRight>
            <TDRight>{this.props.player.points.military}</TDRight>
            <TDRight>
              <DateTimeSpan date={this.props.player.updatedAt} />
            </TDRight>
            <TDRight>
              <DateTimeSpan date={this.props.player.createdAt} />
            </TDRight>
          </tr>
          {this.props.histories.map((history: PlayerHistory) => (
            <tr key={history.id}>
              <TDName name={history.name} />
              <TDRight>{history.reputation}</TDRight>
              <TDRight>{history.level}</TDRight>
              <TDRight>{history.ticks}</TDRight>
              <TDAllianceLinkWithID alliance={history.alliance} />
              <TDRight>{history.points.research}</TDRight>
              <TDRight>{history.points.society}</TDRight>
              <TDRight>{history.points.economy}</TDRight>
              <TDRight>{history.points.military}</TDRight>
              <TDRight>
                <DateTimeSpan date={history.updatedAt} />
              </TDRight>
              <TDRight>
                <DateTimeSpan date={history.overriddenAt} />
              </TDRight>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }
}
