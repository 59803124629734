import React, { Component, ReactElement } from "react";
import { Globals } from "../Globals";
import { ScanObject } from "../views/scan/Scan.interfaces";
import { DateSpan } from "./DateSpan.component";
import {
  TD,
  TDCenter,
  TDLeft,
  TDNameXS,
  TDRight,
  TH,
} from "./Tables.component";

export interface ShipsListProps {
  ships: ScanObject[];
}

export class ShipsList extends Component<ShipsListProps> {
  render(): ReactElement {
    if (!this.props.ships) {
      throw Error("Ships not loaded");
    }
    return (
      <table className="table border-separate">
        <thead className="">
          <tr className="">
            <TH>Position</TH>
            <TH>Bild</TH>
            <TH>Name</TH>
            <TH>NCC</TH>
            <TH>Klasse</TH>
            <TH>Hülle</TH>
            <TH>Schilde</TH>
            <TH>Aktualisiert</TH>
            <TH>Erstellt</TH>
          </tr>
        </thead>
        <tbody>
          {this.props.ships.map((ship) => (
            <tr key={ship.ncc}>
              <TDCenter>{ship.x + "|" + ship.y}</TDCenter>
              <TD>
                <img
                  src={`${Globals.imageUrl}/ships/${ship.image}.gif`}
                  alt={ship.objectClass}
                />
              </TD>
              <TDNameXS name={ship.name} />
              <TDRight>{ship.ncc}</TDRight>
              <TDLeft>{ship.objectClass}</TDLeft>
              <TDCenter>
                {ship.shieldsActive ? "-" : `${ship.huell}/${ship.huellMax}`}
              </TDCenter>
              <TDCenter>
                {ship.shieldsActive
                  ? `${ship.shields}/${ship.shieldsMax}`
                  : "-"}
              </TDCenter>
              <TDRight>
                <DateSpan date={ship.updatedAt} />
              </TDRight>
              <TDRight>
                <DateSpan date={ship.createdAt} />
              </TDRight>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }
}
