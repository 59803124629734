import React, { Component, ReactElement } from "react";
import { Link } from "react-router-dom";
import { PlayerMinimal } from "../views/player/Player.interfaces";

export interface PlayerLinkProps {
  player: PlayerMinimal;
}

export class PlayerLinkWithID extends Component<PlayerLinkProps> {
  render(): ReactElement {
    return (
      <Link to={"/player/" + this.props.player.id}>
        <span
          className="full-hd:whitespace-nowrap"
          dangerouslySetInnerHTML={{ __html: this.props.player.name }}
        ></span>
        <span>{` (${this.props.player.id})`}</span>
      </Link>
    );
  }
}

export class PlayerLink extends Component<PlayerLinkProps> {
  render(): ReactElement {
    return (
      <Link
        to={"/player/" + this.props.player.id}
        className="full-hd:whitespace-nowrap"
        dangerouslySetInnerHTML={{ __html: this.props.player.name }}
      ></Link>
    );
  }
}
