import React, { Component, ReactElement } from "react";
import classNames from "classnames";

export interface ComponentWithClassNames {
  className?: string;
}
export class Filled extends Component<ComponentWithClassNames> {
  render(): ReactElement {
    return (
      <div
        className={classNames(
          "px-2 bg-gray-800 border border-gray-800 border-solid",
          this.props.className
        )}
      >
        {this.props.children}
      </div>
    );
  }
}

export class Bordered extends Component<ComponentWithClassNames> {
  render(): ReactElement {
    return (
      <div
        className={classNames(
          "px-2 border border-gray-800 border-solid ",
          this.props.className
        )}
      >
        {this.props.children}
      </div>
    );
  }
}
