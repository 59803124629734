import React, { Component, ReactElement } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Api } from '../../../api/Api';
import { TD, TDRight, TH } from '../../../components/Tables.component';

export interface OverviewData {
  fieldsCount: number;
  fieldHistoriesCount: number;
  signaturesCount: number;
  signatureHistoriesCount: number;
  scans: number;
  scanHistories: number;
  scanObjects: number;
  scanObjectHistories: number;
  playersCount: number;
  playersHistoryCount: number;
  alliancesCount: number;
  allianceHistoryCount: number;
}

export class Overview extends Component<RouteComponentProps> {
  state: {
    isLoaded: boolean;
    error: Error | null;
    data: OverviewData | undefined;
  };

  constructor(props: RouteComponentProps) {
    super(props);
    this.state = {
      isLoaded: false,
      error: null,
      data: undefined,
    };
  }

  async componentDidMount() {
    try {
      const data = await Api.getInstance().fetchOverview();
      this.setState({
        data: data,
        isLoaded: true,
        error: null,
      });
    } catch (error) {
      console.error(error);
      this.setState({
        data: undefined,
        isLoaded: false,
        error: error,
      });
    }
  }
  render(): ReactElement {
    if (!this.state.isLoaded && this.state.error === null) {
      return <h1>Loading...</h1>;
    }
    if (this.state.error) {
      return <h1>{this.state.error.toString()}</h1>;
    }
    if (!this.state.data) {
      return <h1>Could not retrieve data</h1>;
    }
    const tableRows = [
      {
        entity: 'Fields',
        count: this.state.data.fieldsCount,
      },
      {
        entity: 'FieldHistories',
        count: this.state.data.fieldHistoriesCount,
      },
      {
        entity: 'Signatures',
        count: this.state.data.signaturesCount,
      },
      {
        entity: 'SignatureHistories',
        count: this.state.data.signatureHistoriesCount,
      },
      {
        entity: 'SubspaceScans',
        count: this.state.data.scans,
      },
      {
        entity: 'SubspaceScanHistories',
        count: this.state.data.scanHistories,
      },
      {
        entity: 'SubspaceScanObjects',
        count: this.state.data.scanObjects,
      },
      {
        entity: 'SubspaceScanObjectHistories',
        count: this.state.data.scanObjectHistories,
      },
      {
        entity: 'Players',
        count: this.state.data.playersCount,
      },
      {
        entity: 'PlayerHistories',
        count: this.state.data.playersHistoryCount,
      },
      {
        entity: 'Alliances',
        count: this.state.data.alliancesCount,
      },
      {
        entity: 'AllianceHistories',
        count: this.state.data.allianceHistoryCount,
      },
    ];
    return (
      <section className="container flex justify-center w-full px-2 mx-auto">
        <table className="border-separate table-auto">
          <thead className="">
            <tr className="">
              <TH>Entity</TH>
              <TH>Count</TH>
            </tr>
          </thead>
          <tbody>
            {tableRows.map((row) => (
              <tr key={row.entity}>
                <TD>{row.entity}</TD>
                <TDRight>{row.count}</TDRight>
              </tr>
            ))}
          </tbody>
        </table>
      </section>
    );
  }
}
