import React, { Component, ReactElement } from "react";
import classNames from "classnames";
import { DateHelper } from "../helper/DateHelper";

export interface DateProps {
  date: Date;
  className?: string;
}

export class DateSpan extends Component<DateProps> {
  render(): ReactElement {
    return (
      <span className={classNames(this.props.className)}>
        {DateHelper.getDateRepresentation(this.props.date)}
      </span>
    );
  }
}

export class DateTimeSpan extends Component<DateProps> {
  render(): ReactElement {
    return (
      <span className={classNames(this.props.className)}>
        {DateHelper.getDateTimeRepresentation(this.props.date)}
      </span>
    );
  }
}
