import React, { Component, FormEvent, ReactElement } from "react";
import { Link } from "react-router-dom";
import { Api } from "../../api/Api";
import { DateSpan } from "../../components/DateSpan.component";
import { TDCenter, TDRight, TH } from "../../components/Tables.component";
import { UserLinkWithId } from "../../components/UserLink.component";
import { ScanMinimal } from "./Scan.interfaces";

export interface ScanListProps {}

export enum SortBy {
  ID,
  POSITION,
  OBJECTS,
  UPDATED_AT,
  UPDATED_BY,
  CREATED_AT,
  CREATED_BY,
}

export class ScanList extends Component<ScanListProps> {
  state: {
    isLoaded: boolean;
    error: Error | null;
    page: ScanMinimal[] | undefined;
    pageSize: number;
    pageCurrent: number;
    pageMax: number;
    pageSort: string;
    data: ScanMinimal[] | undefined;
  };

  constructor(props: ScanListProps) {
    super(props);
    this.state = {
      isLoaded: false,
      error: null,
      page: undefined,
      pageSize: 25,
      pageCurrent: 0,
      pageMax: 0,
      pageSort: "",
      data: undefined,
    };
  }

  async componentDidMount() {
    try {
      const data = await Api.getInstance().fetchScans();
      const pageMax = Math.ceil(data.length / this.state.pageSize);
      await this.setState({
        pageMax: pageMax,
        pageSort: "",
        data: data,
        isLoaded: true,
        error: null,
      });
      await this.switchToPage(1);
    } catch (error) {
      console.error(error);
      await this.setState({
        page: undefined,
        data: undefined,
        isLoaded: false,
        error: error,
      });
    }
  }

  async switchToPage(newPageNumber: number) {
    if (
      this.state.data &&
      newPageNumber >= 1 &&
      newPageNumber <= this.state.pageMax
    ) {
      const newPage = this.state.data.slice(
        (newPageNumber - 1) * this.state.pageSize,
        newPageNumber * this.state.pageSize
      );
      await this.setState({
        pageCurrent: newPageNumber,
        page: newPage,
      });
    }
  }

  handleSubmit = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();
    if (this.state.data) {
      try {
        const pageMax = Math.ceil(this.state.data.length / this.state.pageSize);
        await this.setState({
          pageMax: pageMax,
        });
        await this.switchToPage(1);
      } catch (error) {
        console.error(error);
      }
    }
  };

  onChangePageSize = async (event: FormEvent<HTMLInputElement>) => {
    try {
      const pageSize = Number.parseInt(event.currentTarget.value);
      await this.setState({
        pageSize: pageSize,
      });
    } catch (error) {
      console.error(error);
    }
  };

  onFirstPage = async () => {
    await this.switchToPage(1);
  };

  onLastPage = async () => {
    await this.switchToPage(this.state.pageMax);
  };

  onNextPage = async () => {
    await this.switchToPage(this.state.pageCurrent + 1);
  };

  onPreviousPage = async () => {
    await this.switchToPage(this.state.pageCurrent - 1);
  };

  sortPage = async (sortBy: SortBy): Promise<void> => {
    if (this.state.data) {
      let compare = (a: ScanMinimal, b: ScanMinimal) => {
        return a.id - b.id;
      };
      let newPageSort = "";
      switch (sortBy) {
        case SortBy.ID:
          if (this.state.pageSort === "ID_ASC") {
            // ID_DESC
            compare = (a: ScanMinimal, b: ScanMinimal) => {
              return b.id - a.id;
            };
            newPageSort = "ID_DESC";
          } else {
            // ID_ASC
            compare = (a: ScanMinimal, b: ScanMinimal) => {
              return a.id - b.id;
            };
            newPageSort = "ID_ASC";
          }
          break;
        case SortBy.POSITION:
          if (this.state.pageSort === "POSITION_X_ASC") {
            // POSITION_X_DESC
            compare = (a: ScanMinimal, b: ScanMinimal) => {
              if (b.x - a.x === 0) {
                return b.y - a.y;
              }
              return b.x - a.x;
            };
            newPageSort = "POSITION_X_DESC";
          } else if (this.state.pageSort === "POSITION_X_DESC") {
            // POSITION_Y_ASC
            compare = (a: ScanMinimal, b: ScanMinimal) => {
              if (a.y - b.y === 0) {
                return a.x - b.x;
              }
              return a.y - b.y;
            };
            newPageSort = "POSITION_Y_ASC";
          } else if (this.state.pageSort === "POSITION_Y_ASC") {
            // POSITION_Y_DESC
            compare = (a: ScanMinimal, b: ScanMinimal) => {
              if (b.y - a.y === 0) {
                return b.x - a.x;
              }
              return b.y - a.y;
            };
            newPageSort = "POSITION_Y_DESC";
          } else {
            compare = (a: ScanMinimal, b: ScanMinimal) => {
              if (a.x - b.x === 0) {
                return a.y - b.y;
              }
              return a.x - b.x;
            };
            newPageSort = "POSITION_X_ASC";
          }
          break;
        case SortBy.OBJECTS:
          if (this.state.pageSort === "OBJECTS_ASC") {
            // OBJECTS_DESC
            compare = (a: ScanMinimal, b: ScanMinimal) => {
              return b.scanObjects - a.scanObjects;
            };
            newPageSort = "OBJECTS_DESC";
          } else {
            // OBJECTS_ASC
            compare = (a: ScanMinimal, b: ScanMinimal) => {
              return a.scanObjects - b.scanObjects;
            };
            newPageSort = "OBJECTS_ASC";
          }
          break;
        case SortBy.UPDATED_AT:
          if (this.state.pageSort === "UPDATED_AT_ASC") {
            // UPDATED_AT_DESC
            compare = (a: ScanMinimal, b: ScanMinimal) => {
              return (
                new Date(b.updatedAt).getTime() -
                new Date(a.updatedAt).getTime()
              );
            };
            newPageSort = "UPDATED_AT_DESC";
          } else {
            // UPDATED_AT_ASC
            compare = (a: ScanMinimal, b: ScanMinimal) => {
              return (
                new Date(a.updatedAt).getTime() -
                new Date(b.updatedAt).getTime()
              );
            };
            newPageSort = "UPDATED_AT_ASC";
          }
          break;
        case SortBy.UPDATED_BY:
          if (this.state.pageSort === "UPDATED_BY_ASC") {
            // UPDATED_BY_DESC
            compare = (a: ScanMinimal, b: ScanMinimal) => {
              return b.updatedBy.id - a.updatedBy.id;
            };
            newPageSort = "UPDATED_BY_DESC";
          } else {
            // UPDATED_BY_ASC
            compare = (a: ScanMinimal, b: ScanMinimal) => {
              return a.updatedBy.id - b.updatedBy.id;
            };
            newPageSort = "UPDATED_BY_ASC";
          }
          break;
        case SortBy.CREATED_AT:
          if (this.state.pageSort === "CREATED_AT_ASC") {
            // CREATED_AT_DESC
            compare = (a: ScanMinimal, b: ScanMinimal) => {
              return (
                new Date(b.createdAt).getTime() -
                new Date(a.createdAt).getTime()
              );
            };
            newPageSort = "CREATED_AT_DESC";
          } else {
            // CREATED_AT_ASC
            compare = (a: ScanMinimal, b: ScanMinimal) => {
              return (
                new Date(a.createdAt).getTime() -
                new Date(b.createdAt).getTime()
              );
            };
            newPageSort = "CREATED_AT_ASC";
          }
          break;
        case SortBy.CREATED_BY:
          if (this.state.pageSort === "CREATED_BY_ASC") {
            // CREATED_BY_DESC
            compare = (a: ScanMinimal, b: ScanMinimal) => {
              return b.createdBy.id - a.createdBy.id;
            };
            newPageSort = "CREATED_BY_DESC";
          } else {
            // CREATED_BY_ASC
            compare = (a: ScanMinimal, b: ScanMinimal) => {
              return a.createdBy.id - b.createdBy.id;
            };
            newPageSort = "CREATED_BY_ASC";
          }
          break;
        default:
          break;
      }
      const sortedData = this.state.data.sort(compare);
      await this.setState({
        data: sortedData,
        pageSort: newPageSort,
      });
      await this.switchToPage(1);
    }
  };

  render(): ReactElement {
    if (!this.state.isLoaded && this.state.error === null) {
      return <h1>Loading...</h1>;
    }
    if (this.state.error) {
      return <h1>{this.state.error.toString()}</h1>;
    }
    if (!this.state.page) {
      return <h1>Could not retrieve data</h1>;
    }
    return (
      <section className="flex flex-col justify-center mx-auto">
        <form onSubmit={this.handleSubmit} noValidate={true}>
          <div className="flex flex-row p-1 m-2 mb-2 text-left border border-gray-800 border-solid">
            <span className="flex-grow bg-gray-800"></span>
            <span className="p-1 ml-1 bg-gray-800 border border-gray-800 border-solid">
              Übersicht aller Subraum-Scans
            </span>
            <label className="px-2 py-1 ml-1 bg-gray-800 border border-gray-800 border-solid">
              Anzahl pro Seite
            </label>
            <input
              className="w-12 px-2 py-1 mr-1 text-center text-black bg-gray-700 border-2 border-gray-500 border-solid focus:border-orange-300"
              type="text"
              name="x"
              value={this.state.pageSize}
              onChange={this.onChangePageSize}
            />
            <button className="px-2 py-1 mr-1 bg-gray-800 border border-white border-solid hover:border-orange-300 hover:text-orange-300">
              Anzeigen
            </button>
            <span className="flex-grow bg-gray-800"></span>
          </div>
        </form>
        <div className="grid grid-cols-1 gap-2 mx-auto">
          <div className="flex flex-row p-1 text-left border border-gray-800 border-solid">
            <button
              onClick={this.onFirstPage}
              className="px-2 py-1 mr-1 bg-gray-800 border border-white border-solid hover:border-orange-300 hover:text-orange-300"
            >
              {"Erste"}
            </button>
            <button
              onClick={this.onPreviousPage}
              className="px-2 py-1 mr-1 bg-gray-800 border border-white border-solid hover:border-orange-300 hover:text-orange-300"
            >
              {"Vorherige"}
            </button>
            <span className="flex-grow bg-gray-800"></span>
            <span className="p-1 mx-1 bg-gray-800 border border-gray-800 border-solid">
              {this.state.pageCurrent} / {this.state.pageMax}
            </span>
            <span className="flex-grow bg-gray-800"></span>
            <button
              onClick={this.onNextPage}
              className="px-2 py-1 ml-1 bg-gray-800 border border-white border-solid hover:border-orange-300 hover:text-orange-300"
            >
              {"Nächste"}
            </button>
            <button
              onClick={this.onLastPage}
              className="px-2 py-1 ml-1 bg-gray-800 border border-white border-solid hover:border-orange-300 hover:text-orange-300"
            >
              {"Letzte"}
            </button>
          </div>
          <table className="table border-separate">
            <thead className="">
              <tr>
                <TH onClick={() => this.sortPage(SortBy.ID)}>ID</TH>
                <TH onClick={() => this.sortPage(SortBy.POSITION)}>Position</TH>
                <TH onClick={() => this.sortPage(SortBy.OBJECTS)}>Objekte</TH>
                <TH onClick={() => this.sortPage(SortBy.UPDATED_AT)}>
                  Aktualisiert am
                </TH>
                <TH onClick={() => this.sortPage(SortBy.UPDATED_BY)}>
                  Aktualisiert von
                </TH>
                <TH onClick={() => this.sortPage(SortBy.CREATED_AT)}>
                  Erstellt am
                </TH>
                <TH onClick={() => this.sortPage(SortBy.CREATED_BY)}>
                  Erstellt von
                </TH>
              </tr>
            </thead>
            <tbody>
              {this.state.page.map((scan: ScanMinimal) => (
                <tr key={scan.id}>
                  <TDRight>
                    <Link to={"/scan/" + scan.id}>{scan.id}</Link>
                  </TDRight>
                  <TDCenter>{scan.x + "|" + scan.y}</TDCenter>
                  <TDRight>{scan.scanObjects}</TDRight>
                  <TDRight>
                    <DateSpan date={scan.updatedAt} />
                  </TDRight>
                  <TDRight>
                    <UserLinkWithId user={scan.updatedBy} />
                  </TDRight>
                  <TDRight>
                    <DateSpan date={scan.createdAt} />
                  </TDRight>
                  <TDRight>
                    <UserLinkWithId user={scan.createdBy} />
                  </TDRight>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </section>
    );
  }
}
