import { Globals } from "../Globals";
import { CreateUserDto, User } from "../views/admin/users/User.interface";
import { Scan, ScanMap, ScanMinimal } from "../views/scan/Scan.interfaces";
import {
  FieldMap,
  MapArea,
} from "../views/stellar-cartography/StellarCartography.interfaces";

export class Api {
  private static instance: Api;

  public static getInstance() {
    if (!Api.instance) {
      Api.instance = new Api();
    }
    return Api.instance;
  }

  private username: string = "";
  private password: string = "";

  setCredentials(username: string, password: string) {
    this.username = username;
    this.password = password;
  }

  async fetchToken(): Promise<string> {
    console.log("TokenURL: " + Globals.tokenUrl);
    const tokenResponse = await fetch(Globals.tokenUrl, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        username: this.username,
        password: this.password,
      }),
    });
    if (!tokenResponse.ok) {
      throw Error(tokenResponse.statusText);
    }
    const response = await tokenResponse.json();
    return response.access_token;
  }

  private async createHeader() {
    const token = await this.fetchToken();
    return {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    };
  }

  async fetchFields(area: MapArea): Promise<FieldMap> {
    const mapResponse = await fetch(`${Globals.apiUrl}/fields/area`, {
      method: "POST",
      headers: await this.createHeader(),
      body: JSON.stringify(area),
    });
    if (!mapResponse.ok) {
      throw Error(mapResponse.statusText);
    }
    return await mapResponse.json();
  }

  async fetchScansArea(area: MapArea): Promise<ScanMap> {
    const mapResponse = await fetch(`${Globals.apiUrl}/subspace-scan/area`, {
      method: "POST",
      headers: await this.createHeader(),
      body: JSON.stringify(area),
    });
    if (!mapResponse.ok) {
      throw Error(mapResponse.statusText);
    }
    return await mapResponse.json();
  }

  async fetchAlliance(id: number) {
    const allianceResponse = await fetch(`${Globals.apiUrl}/alliance/${id}`, {
      headers: await this.createHeader(),
    });
    if (!allianceResponse.ok) {
      throw Error(allianceResponse.statusText);
    }
    return await allianceResponse.json();
  }

  async fetchOverview() {
    const overviewResponse = await fetch(`${Globals.apiUrl}/overview`, {
      headers: await this.createHeader(),
    });
    if (!overviewResponse.ok) {
      throw Error(overviewResponse.statusText);
    }
    const data = await overviewResponse.json();
    return data;
  }

  async fetchUser(id: number): Promise<User> {
    const url = `${Globals.baseUrl}/user/${id}`;
    console.log("GET " + url);
    const userResponse = await fetch(url, {
      headers: await this.createHeader(),
    });
    if (!userResponse.ok) {
      throw Error(userResponse.statusText);
    }
    return await userResponse.json();
  }

  async postCreateUser(user: CreateUserDto): Promise<User> {
    if (!user) {
      return Promise.reject("User not set");
    }
    const url = `${Globals.baseUrl}/user/`;
    const response = await fetch(url, {
      method: "POST",
      headers: await this.createHeader(),
      body: JSON.stringify(user),
    });
    if (!response.ok) {
      if (response.status < 500) {
        const body = (await response.json()) as {
          statusCode: number;
          message: string;
          error: string;
        };
        throw Error(body.message);
      }
      throw Error(response.statusText + "(" + response.status + ")");
    }
    return response.json();
  }

  async postUpdateUser(user: User): Promise<User> {
    if (!user) {
      return Promise.reject("User not set");
    }
    const url = `${Globals.baseUrl}/user/${user.id}`;

    const response = await fetch(url, {
      method: "POST",
      headers: await this.createHeader(),
      body: JSON.stringify(user),
    });
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response.json();
  }

  async deleteUser(id: number): Promise<void> {
    if (!id) {
      return Promise.reject("Id not set!");
    }
    const url = `${Globals.baseUrl}/user/${id}`;
    const response = await fetch(url, {
      method: "DELETE",
      headers: await this.createHeader(),
    });
    if (!response.ok) {
      throw Error(response.statusText);
    }
  }

  async fetchUsers() {
    const url = `${Globals.baseUrl}/user`;
    console.log("GET " + url);
    const usersResponse = await fetch(url, {
      headers: await this.createHeader(),
    });
    if (!usersResponse.ok) {
      throw Error(usersResponse.statusText);
    }
    return await usersResponse.json();
  }

  async fetchPlayer(id: number) {
    const url = `${Globals.apiUrl}/player/${id}`;
    const playerResponse = await fetch(url, {
      headers: await this.createHeader(),
    });
    if (!playerResponse.ok) {
      throw Error(playerResponse.statusText);
    }
    return await playerResponse.json();
  }

  async fetchPlayers() {
    const url = `${Globals.apiUrl}/player`;
    const playersResponse = await fetch(url, {
      headers: await this.createHeader(),
    });
    if (!playersResponse.ok) {
      throw Error(playersResponse.statusText);
    }
    return await playersResponse.json();
  }

  async fetchActivePlayers() {
    const url = `${Globals.apiUrl}/player/active`;
    const playersResponse = await fetch(url, {
      headers: await this.createHeader(),
    });
    if (!playersResponse.ok) {
      throw Error(playersResponse.statusText);
    }
    return await playersResponse.json();
  }

  async fetchScan(id: number): Promise<Scan> {
    const url = `${Globals.apiUrl}/subspace-scan/${id}`;
    const scanResponse = await fetch(url, {
      headers: await this.createHeader(),
    });
    if (!scanResponse.ok) {
      throw Error(scanResponse.statusText);
    }
    const json = await scanResponse.json();
    console.log(json);
    return json;
  }

  async fetchScans(): Promise<ScanMinimal[]> {
    const url = `${Globals.apiUrl}/subspace-scan`;
    const scansResponse = await fetch(url, {
      headers: await this.createHeader(),
    });
    if (!scansResponse.ok) {
      throw Error(scansResponse.statusText);
    }
    const json = await scansResponse.json();
    console.log(json);
    return json;
  }

  async fetchAlliances() {
    const url = `${Globals.apiUrl}/alliance`;
    const alliancesResponse = await fetch(url, {
      headers: await this.createHeader(),
    });
    if (!alliancesResponse.ok) {
      throw Error(alliancesResponse.statusText);
    }
    const json = await alliancesResponse.json();
    console.log(json);
    return json;
  }
}
