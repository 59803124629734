import React, { Component, ReactElement } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Api } from "../../../api/Api";
import { DateSpan } from "../../../components/DateSpan.component";
import { TDRight, TH } from "../../../components/Tables.component";
import { UserLink } from "../../../components/UserLink.component";
import { AuthRoutes } from "../../App";
import { User } from "./User.interface";

export class UserList extends Component<RouteComponentProps> {
  state: {
    users: User[] | undefined;
    error: Error | undefined;
  };

  constructor(props: RouteComponentProps) {
    super(props);
    this.state = {
      users: undefined,
      error: undefined,
    };
  }

  async componentDidMount() {
    await this.loadAllUsers();
  }

  private async loadAllUsers() {
    try {
      const users = await Api.getInstance().fetchUsers();
      console.log(users);
      this.setState({
        users: users,
        error: undefined,
      });
    } catch (error) {
      console.error(error);
      this.setState({
        users: undefined,
        error: error,
      });
    }
  }

  private onCreateUser = async () => {
    this.props.history.push(AuthRoutes.adminUserCreate);
  };

  private onDeleteUser = async (id: number) => {
    await Api.getInstance().deleteUser(id);
    await this.loadAllUsers();
  };

  private getUserList(): ReactElement {
    return (
      <table className="table mt-4 border-separate">
        <thead className="">
          <tr className="">
            <TH>ID</TH>
            <TH>Name</TH>
            <TH>Spieler-ID</TH>
            <TH>Rollen</TH>
            <TH>Notizen</TH>
            <TH>Aktualisiert</TH>
            <TH>Erstellt</TH>
            <TH>Aktionen</TH>
          </tr>
        </thead>
        <tbody>
          {this.state.users?.map((user: User) => (
            <tr key={user.id}>
              <TDRight>
                <UserLink user={user}>{user.id}</UserLink>
              </TDRight>
              <TDRight>
                <UserLink user={user}>{user.name}</UserLink>
              </TDRight>
              <TDRight>{user.playerId}</TDRight>
              <TDRight>{user.roles.join(", ")}</TDRight>
              <TDRight>{user.note}</TDRight>
              <TDRight>
                <DateSpan date={user.updatedAt} />
              </TDRight>
              <TDRight>
                <DateSpan date={user.createdAt} />
              </TDRight>
              <TDRight>
                <button
                  className="inline-block p-2 m-1 leading-none text-white bg-gray-800 border border-white rounded hover:border-orange-300 hover:text-orange-300"
                  onClick={() => this.onDeleteUser(user.id)}
                >
                  Löschen
                </button>
              </TDRight>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }

  render(): ReactElement {
    if (!this.state.users && !this.state.error) {
      return <h1>Loading...</h1>;
    }
    if (this.state.error) {
      return <h1>{this.state.error.toString()}</h1>;
    }
    if (!this.state.users) {
      return <h1>Could not retrieve data</h1>;
    }
    return (
      <section className="flex flex-col px-2 justify-evenly">
        <div className="flex flex-row flex-wrap justify-start flex-grow mt-2">
          <button
            className="inline-block px-4 py-2 mx-1 leading-none text-white bg-gray-800 border border-white rounded hover:border-orange-300 hover:text-orange-300"
            onClick={this.onCreateUser}
          >
            Benutzer hinzufügen
          </button>
        </div>
        {this.getUserList()}
      </section>
    );
  }
}
