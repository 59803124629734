import React, { Component, ReactElement } from "react";
import { Globals } from "../../Globals";
import {
  TDCenter,
  TDLeft,
  TDName,
  TDPlayerLinkWithID,
  TDRight,
  TH,
} from "../../components/Tables.component";
import { PlayerMinimal } from "../player/Player.interfaces";
import { Scan, ScanObject } from "../scan/Scan.interfaces";

interface TableRow {
  id: number;
  image: string;
  name: string;
  ncc: string;
  objectClass: string;
  huell: string;
  shields: string;
  player: PlayerMinimal;
}

export interface SectorDetailsProps {
  scan: Scan | undefined;
}

export class SectorDetails extends Component<SectorDetailsProps> {
  private getColonies(): TableRow[] {
    if (!this.props.scan) {
      return [];
    }
    return this.props.scan.scanObjects
      .filter((scanObject) => scanObject.objectClass === "Kolonie")
      .map((colony: ScanObject) => {
        return {
          id: colony.id,
          image: `${Globals.imageUrl}/planets/${colony.image}.gif`,
          name: colony.name,
          ncc: "-",
          objectClass: colony.objectClass,
          huell: "",
          shields: "",
          player: colony.owner,
        };
      });
  }

  private getShips(): TableRow[] {
    if (!this.props.scan) {
      return [];
    }
    return this.props.scan.scanObjects
      .filter((scanObject) => scanObject.objectClass !== "Kolonie")
      .map((ship) => {
        return {
          id: ship.id,
          image: `${Globals.imageUrl}/ships/${ship.image}.gif`,
          name: ship.name,
          ncc: ship.ncc,
          objectClass: ship.objectClass,
          huell: ship.shieldsActive ? `${ship.huell}/${ship.huellMax}` : "-",
          shields: ship.shieldsActive
            ? `${ship.shields}/${ship.shieldsMax}`
            : "-",
          player: ship.owner,
        };
      });
  }

  render(): ReactElement {
    const tableRows: TableRow[] = this.getColonies().concat(this.getShips());
    return (
      <table className="table w-full border-separate">
        <thead className="">
          <tr>
            <TH>Bild</TH>
            <TH>Name</TH>
            <TH>NCC</TH>
            <TH>Klasse</TH>
            <TH>Hülle</TH>
            <TH>Schilde</TH>
            <TH>Von</TH>
          </tr>
        </thead>
        <tbody className="color-default">
          {tableRows.map((row: TableRow) => (
            <tr key={row.id}>
              <TDCenter>
                <img src={row.image} alt={row.objectClass} />
              </TDCenter>
              <TDName name={row.name}></TDName>
              <TDRight>{row.ncc}</TDRight>
              <TDLeft>{row.objectClass}</TDLeft>
              <TDCenter>{row.huell}</TDCenter>
              <TDCenter>{row.shields}</TDCenter>
              <TDPlayerLinkWithID player={row.player}></TDPlayerLinkWithID>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }
}
