import React, { Component, ReactElement } from "react";
import { Link } from "react-router-dom";
import { UserMinimal } from "../views/admin/users/User.interface";

export interface UserLinkProps {
  user: UserMinimal;
}

export class UserLinkWithId extends Component<UserLinkProps> {
  render(): ReactElement {
    return (
      <Link
        to={"/admin/user/" + this.props.user.id}
        className="full-hd:whitespace-nowrap"
      >
        {`${this.props.user.name} (${this.props.user.id})`}
      </Link>
    );
  }
}

export class UserLink extends Component<UserLinkProps> {
  render(): ReactElement {
    return (
      <Link
        to={"/admin/user/" + this.props.user.id}
        className="full-hd:whitespace-nowrap"
      >
        {this.props.children}
      </Link>
    );
  }
}
