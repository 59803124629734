import React, { ChangeEvent, Component, FormEvent, ReactElement } from "react";
import { RouteComponentProps } from "react-router";
import { Api } from "../../../api/Api";
import { Filled } from "../../../components/Divs.component";
import { UserRoles } from "../../../Globals";
import { AuthRoutes } from "../../App";

interface Validation {
  field: string;
  error: string;
}

enum Fields {
  name,
  passwort1,
  passwort2,
  playerId,
  roles,
  note,
}

export class UserCreate extends Component<RouteComponentProps> {
  state: {
    error: Error | null;
    validation: Validation[];
    name: string;
    passwort1: string;
    passwort2: string;
    playerId: number;
    roles: UserRoles[];
    note: string;
  };

  constructor(props: RouteComponentProps) {
    super(props);
    console.log("hello");
    this.state = {
      error: null,
      validation: [],
      name: "",
      passwort1: "",
      passwort2: "",
      playerId: 0,
      roles: [UserRoles.none],
      note: "",
    };
  }

  onSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    console.log("Save clicked");

    await this.validate();

    if (this.state.validation.length !== 0) {
      console.error("Speichern nicht möglich: Validierung fehlgeschlagen");
      return;
    }

    try {
      const user = await Api.getInstance().postCreateUser({
        name: this.state.name,
        password: this.state.passwort1,
        playerId: this.state.playerId,
        roles: this.state.roles,
        note: this.state.note,
      });

      console.log(user);

      this.props.history.push(
        AuthRoutes.adminUserDetail.toString().replace(":id", "" + user.id)
      );
    } catch (error) {
      console.error(error);
      this.setState({ error: error });
    }
  };

  onReset = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    console.log("Reset clicked");
    await this.setState({
      error: null,
      validation: [],
      name: "",
      passwort1: "",
      passwort2: "",
      playerId: undefined,
      roles: [UserRoles.none],
      note: "",
    });
  };

  onChange = async (event: ChangeEvent<HTMLInputElement>) => {
    switch (event.currentTarget.name) {
      case Fields.name.toString():
        await this.setState({ name: event.currentTarget.value });
        break;
      case Fields.playerId.toString():
        await this.setState({
          playerId: Number.parseInt(event.currentTarget.value),
        });
        break;
      case Fields.passwort1.toString():
        await this.setState({ passwort1: event.currentTarget.value });
        break;
      case Fields.passwort2.toString():
        await this.setState({ passwort2: event.currentTarget.value });
        break;
      case Fields.note.toString():
        await this.setState({ note: event.currentTarget.value });
        break;
      default:
        break;
    }
    await this.validate();
  };

  private async validate() {
    // Delete old validations
    const validation = [];
    // Check name
    if (this.state.name === "") {
      validation.push({
        field: "name",
        error: "Name muss gesetzt sein",
      });
    }
    // Check passwort
    if (this.state.passwort1 === "") {
      validation.push({
        field: "passwort1",
        error: "Passwort muss gesetzt sein",
      });
    }
    if (this.state.passwort1 !== this.state.passwort2) {
      validation.push({
        field: "passwort1",
        error: "Passwörter stimmen nicht überein",
      });
    }
    // Save new validations
    await this.setState({
      validation: validation,
    });
  }

  onSelectChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const allRoles = Object.values(UserRoles);

    let selectedOptions = Array.from(
      event.target.selectedOptions,
      (option) => option.value
    );

    // Map strings to UserRoles
    let roles = selectedOptions.map(
      (option) =>
        allRoles.find((role) => role.toString() === option) || UserRoles.none
    );

    // Remove duplicates
    roles = roles.filter(function (item, pos) {
      return roles.indexOf(item) === pos;
    });

    this.setState({
      roles: roles,
    });
  };

  onTextAreaChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({ note: event.target.value });
  };

  render(): ReactElement {
    return (
      <section className="flex flex-col justify-center">
        <h1 className="px-2 m-2 mb-2 text-center bg-gray-800 border border-gray-800 border-solid">
          Neuen Benutzer anlegen
        </h1>

        {this.state.error && (
          <h2 className="px-2 m-2 text-center bg-gray-800 border-4 border-red-800 border-solid">
            Speichern fehlgeschlagen: {this.state.error.message}
          </h2>
        )}

        {this.state.validation.map((val) => (
          <h2 className="px-2 m-2 text-center bg-gray-800 border-4 border-red-800 border-solid">
            {val.error}
          </h2>
        ))}

        <form
          id="create-user-form"
          onSubmit={this.onSubmit}
          onReset={this.onReset}
          noValidate={true}
          className="grid grid-cols-4 gap-1 p-1 mx-auto mt-8 border-4 border-gray-700 border-solid"
        >
          <Filled>Name</Filled>
          <input
            name={Fields.name.toString()}
            type="text"
            placeholder="Name"
            className="w-full col-span-3 px-2 text-left text-black bg-gray-700 border-2 border-gray-500 border-solid focus:border-orange-300"
            value={this.state.name}
            onChange={this.onChange}
          />

          <Filled>Passwort</Filled>
          <input
            name={Fields.passwort1.toString()}
            type="text"
            placeholder="Passwort"
            className="w-full col-span-3 px-2 text-left text-black bg-gray-700 border-2 border-gray-500 border-solid focus:border-orange-300"
            value={this.state.passwort1}
            onChange={this.onChange}
          />

          <Filled>Passwort wiederholung</Filled>
          <input
            name={Fields.passwort2.toString()}
            type="text"
            placeholder="Passwort wiederholung"
            className="w-full col-span-3 px-2 text-left text-black bg-gray-700 border-2 border-gray-500 border-solid focus:border-orange-300"
            value={this.state.passwort2}
            onChange={this.onChange}
          />

          <Filled>Spieler-Id</Filled>
          <input
            name={Fields.playerId.toString()}
            type="number"
            placeholder="Spieler-Id"
            className="w-full col-span-3 px-2 text-left text-black bg-gray-700 border-2 border-gray-500 border-solid focus:border-orange-300"
            value={this.state.playerId}
            onChange={this.onChange}
          />

          <Filled>Rollen</Filled>
          <select
            multiple={true}
            name={Fields.roles.toString()}
            className="w-full col-span-3 px-2 text-left text-black bg-gray-700 border-2 border-gray-500 border-solid focus:border-orange-300"
            value={this.state.roles}
            onChange={this.onSelectChange}
          >
            {Object.values(UserRoles).map((role: string) => (
              <option value={role}>{role}</option>
            ))}
          </select>

          <Filled>Notizen</Filled>
          <textarea
            rows={5}
            cols={40}
            name={Fields.note.toString()}
            placeholder="Keine Notiz gesetzt"
            className="w-full col-span-3 px-2 text-left text-black bg-gray-700 border-2 border-gray-500 border-solid focus:border-orange-300"
            value={this.state.note}
            onChange={this.onTextAreaChange}
          />
          <div></div>
          <div className="col-span-3">
            <button
              type="submit"
              disabled={this.state.validation.length !== 0}
              className="w-32 py-1 mt-1 bg-gray-800 border border-gray-500 border-solid rounded-md disabled:opacity-50 hover:border-orange-300 hover:text-orange-300"
            >
              {/* TODO: Ausgrauen wenn Disabled! */}
              Speichern
            </button>
            <button
              type="reset"
              className="w-32 py-1 mt-1 ml-2 bg-gray-800 border border-gray-500 border-solid rounded-md hover:border-orange-300 hover:text-orange-300"
            >
              Abbrechen
            </button>
          </div>
        </form>
      </section>
    );
  }
}
