import React from "react";
import { Route, RouteComponentProps, RouteProps } from "react-router-dom";
import { CurrentUser } from "../App";
import { UserRoles } from "../../Globals";
import { Login } from "./Login.component";
import { Unauthorized } from "./Unauthorized.component";

interface AuthRouteProps extends RouteProps {
  Component: React.ComponentType<any>;
  requiredRole: UserRoles;
  currentUser: CurrentUser;
}

export interface AuthComponentProps extends RouteComponentProps {
  requiredRole: UserRoles;
  currentUser: CurrentUser;
}

export const AuthRoute = ({
  Component,
  path,
  exact = false,
  requiredRole,
  currentUser,
}: AuthRouteProps): JSX.Element => {
  const hasAuth = currentUser.token !== "" && currentUser.name !== "";
  let hasRole = false;
  if (UserRoles.none === requiredRole) {
    hasRole = true;
  } else if (currentUser.roles.includes(requiredRole)) {
    hasRole = true;
  } else if (currentUser.roles.includes(UserRoles.admin)) {
    hasRole = true;
  }
  // console.log("AuthRoute:");
  if (hasAuth && hasRole) {
    return (
      <Route
        exact={exact}
        path={path}
        render={(props: RouteComponentProps) => (
          <Component currentUser={currentUser} {...props} />
        )}
      />
    );
  }
  if (hasAuth) {
    return (
      <Route
        exact={exact}
        path={path}
        render={(props: RouteComponentProps) => (
          <Unauthorized
            currentUser={currentUser}
            requiredRole={requiredRole}
            {...props}
          />
        )}
      />
    );
  } else {
    return (
      <Route
        exact={exact}
        path={path}
        render={(props: RouteComponentProps) => (
          <Login currentUser={currentUser} {...props} />
        )}
      />
    );
  }
};
