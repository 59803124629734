import React, { Component, ReactElement } from "react";
import { Link } from "react-router-dom";
import { Globals } from "../../Globals";
import { ScanMap } from "../scan/Scan.interfaces";
import { FieldMap } from "./StellarCartography.interfaces";
import "./Starmap.css";

export interface StarMapProps {
  mapClick(x: number, y: number): any;
  center: {
    x: number;
    y: number;
    r: number;
  };
  fields: FieldMap;
  scans: ScanMap;
}

export class StarMap extends Component<StarMapProps> {
  render(): ReactElement {
    if (!this.props) {
      return <h1>Error: Data missing</h1>;
    }
    const center = this.props.center;
    // Create table head
    const tableHeads: ReactElement[] = [];
    for (let x = center.x - center.r; x <= center.x + center.r; x++) {
      tableHeads.push(
        <th className="bg-gray-800" key={"x=" + x}>
          {x}
        </th>
      );
    }
    const rows: ReactElement[] = [];
    for (let y = center.y - center.r; y <= center.y + center.r; y++) {
      const cells: ReactElement[] = [];
      cells.push(<th className="bg-gray-800" key={"y=" + y}>{y}</th>);
      for (let x = center.x - center.r; x <= center.x + center.r; x++) {
        const field = this.props.fields[y][x];
        let title = `${x}|${y} - Weltraum`;
        for (const special of field.specials) {
          title += " - " + special;
        }
        let color = field.color;
        if (x === center.x && y === center.y) {
          // Mark center on map
          color = "666666";
        }

        let scanObjectsContent: string | number = "";
        if (y in this.props.scans && x in this.props.scans[y]) {
          scanObjectsContent = this.props.scans[y][x].scanObjects.length;
        }
        const tdStyle = {
          border: `1px solid #${color}`,
        };
        const divStyle = {
          height: '32px',
          width: '32px',
          backgroundImage: `url(${Globals.imageUrl}/map/${field.image}.gif)`,
        };
        cells.push(
          <td
            key={x + "|" + y}
            title={title}
            style={tdStyle}
            onClick={async () => await this.props.mapClick(x, y)}
          >
            <div style={divStyle}>
              <Link to={`/stellar-cartography/${y}/${x}`}>
                <div className="pt-1 font-bold text-center text-shadow">{scanObjectsContent}</div>
              </Link>
            </div>
          </td>
        );
      }
      rows.push(<tr key={y}>{cells}</tr>);
    }

    return (
      <table className="ml-0 text-sm border-separate">
        <thead>
          <tr>
            <th className="bg-gray-800">x|y</th>
            {tableHeads}
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </table>
    );
  }
}
