export class Globals {
  public static readonly baseUrl = Globals.getBaseUrl();
  public static readonly apiUrl = `${Globals.getBaseUrl()}/api`;
  public static readonly tokenUrl = `${Globals.getBaseUrl()}/auth/login`;
  public static readonly imageUrl = `http://stc2.stciv.de/game/images`;

  private static getBaseUrl() {
    switch (process.env.REACT_APP_STAGE) {
      case "prod": {
        return `https://stc2-db.space-pirates.net/api-v1`;
      }
      case "dev": {
        return `http://localhost:3333`;
      }
      case "local": {
        return `http://localhost:3333`;
      }
      default: {
        throw Error('REACT_APP_STAGE fehlt');
      }
    }
  }
}

export enum UserRoles {
  admin = "admin",
  user = "user",
  none = "none",
}
