import React, { Component, ReactElement } from "react";
import { RouteComponentProps } from "react-router-dom";
import { FieldMap, MapArea } from "./StellarCartography.interfaces";
import { ScanMap } from "../scan/Scan.interfaces";
import { StarMap } from "./Starmap.component";
import { Api } from "../../api/Api";
import { SectorDetails } from "./SectorDetails.component";
import { SectorHeader } from "./SectorHeader.component";

interface MatchParams {
  x: string | undefined;
  y: string | undefined;
}
export interface Data {
  fields: FieldMap;
  scans: ScanMap;
}

export class StellarCartography extends Component<
  RouteComponentProps<MatchParams>
> {
  state: {
    isLoaded: boolean;
    error: Error | undefined;
    selected: {
      x: number;
      y: number;
    };
    center: {
      x: number;
      y: number;
      r: number;
    };
    data: Data | undefined;
  };

  constructor(props: RouteComponentProps<MatchParams>) {
    super(props);
    const center = {
      x: 257,
      y: 18,
      r: 10,
    };
    if (this.props.match.params.x) {
      center.x = Number.parseInt(this.props.match.params.x);
    }
    if (this.props.match.params.y) {
      center.y = Number.parseInt(this.props.match.params.y);
    }
    this.state = {
      isLoaded: false,
      error: undefined,
      selected: {
        x: center.x,
        y: center.y,
      },
      center: center,
      data: undefined,
    };
  }

  async componentDidMount() {
    await this.fetchData(
      this.state.center.x,
      this.state.center.y,
      this.state.center.r
    );
  }

  onSubmit = async (x: number, y: number) => {
    await this.fetchData(x, y, this.state.center.r);
  };

  onMapClick = async (x: number, y: number) => {
    if (this.state.selected?.x === x && this.state.selected?.y === y) {
      await this.fetchData(x, y, this.state.center.r);
    } else {
      this.setState({
        selected: {
          x: x,
          y: y,
        },
      });
    }
  };

  async fetchData(x: number, y: number, r: number) {
    const area: MapArea = {
      startX: x - r,
      startY: y - r,
      endX: x + r,
      endY: y + r,
    };
    let data = this.state.data;
    let error = undefined;
    try {
      const fields = await Api.getInstance().fetchFields(area);
      const scans = await Api.getInstance().fetchScansArea(area);
      if (!data) {
        data = {
          fields: fields,
          scans: scans,
        };
      } else {
        data.fields = fields;
        data.scans = scans;
      }
    } catch (err) {
      error = err;
      console.error(error);
    }
    this.setState({
      data: data,
      selected: {
        x: x,
        y: y,
      },
      center: {
        x: x,
        y: y,
        r: r,
      },
      isLoaded: data !== undefined,
      error: error,
    });
  }

  private getSectorDetailProps() {
    const state = this.state;
    const x = state.selected ? state.selected.x : state.center.x;
    const y = state.selected ? state.selected.y : state.center.y;
    const scan = state.data?.scans[y] ? state.data?.scans[y][x] : undefined;
    return {
      x: x,
      y: y,
      scan: scan,
      onSubmit: this.onSubmit,
    };
  }

  render(): ReactElement {
    if (!this.state.isLoaded && !this.state.error) {
      return <h1>Loading...</h1>;
    }
    if (this.state.error) {
      return <h1>{this.state.error.toString()}</h1>;
    }
    if (!this.state.data) {
      return <h1>Could not retrieve data</h1>;
    }
    return (
      <section className="flex flex-row flex-wrap max-w-full pt-2 pl-2 pr-2 full-hd:pr-0 full-hd:flex-nowrap">
        <div className="w-full mb-2 full-hd:hidden">
          <SectorHeader {...this.getSectorDetailProps()} />
        </div>
        <div>
          <StarMap
            mapClick={this.onMapClick}
            center={this.state.center}
            fields={this.state.data.fields}
            scans={this.state.data.scans}
          />
        </div>
        <div className="flex-grow mt-2 full-hd:mt-0 full-hd:mx-2 ">
          <div className="hidden full-hd:block full-hd:mb-2">
            <SectorHeader {...this.getSectorDetailProps()} />
          </div>
          <SectorDetails {...this.getSectorDetailProps()} />
        </div>
      </section>
    );
  }
}
