import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { AuthRoutes, CurrentUser } from "../views/App";
import Logout from "../components/Logout.component";
import { UserRoles } from "../Globals";
export interface NavigationProps {
  currentUser: CurrentUser;
}

export class Navigation extends Component<NavigationProps> {
  private isUser(): boolean {
    return (
      this.props.currentUser.roles.includes(UserRoles.user) ||
      this.props.currentUser.roles.includes(UserRoles.admin)
    );
  }

  private isAdmin(): boolean {
    return this.props.currentUser.roles.includes(UserRoles.admin);
  }

  render() {
    return (
      <header className="sticky z-50 w-full">
        <nav className="flex flex-wrap items-center justify-between p-2 m-2 bg-gray-700 border border-gray-700 border-solid rounded-lg">
          {/* Always visible */}
          <div className="flex items-center flex-shrink-0 mr-6 text-white">
            <img
              className="w-8 h-8 mr-2 fill-current"
              src="/favicon.ico"
              alt="Logo"
            />
            <a
              className="text-xl font-semibold text-teal-200 hover:text-orange-300"
              href={AuthRoutes.welcome}
            >
              STC2-DB
            </a>
          </div>
          {/* Button only visible if menu is collapsed */}
          <div className="block half-hd:hidden">
            <button
              className="flex items-center px-3 py-2 text-teal-200 border border-teal-400 rounded hover:text-orange-300"
              type="button"
            >
              <svg
                className="w-3 h-3 fill-current"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>Menu</title>
                <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
              </svg>
            </button>
          </div>
          {/* Menu content */}
          <div
            className="flex-grow block w-full half-hd:flex half-hd:items-center half-hd:w-auto"
            id="navbarSupportedContent"
          >
            {this.isUser() && (
              <ul className="">
                <li className="block mt-4 mr-4 half-hd:inline-block half-hd:mt-0">
                  <NavLink
                    className="text-teal-200 hover:text-orange-300"
                    to={AuthRoutes.playerList}
                  >
                    Spielerliste
                  </NavLink>
                </li>
                <li className="block mt-4 mr-4 half-hd:inline-block half-hd:mt-0">
                  <NavLink
                    className="text-teal-200 hover:text-orange-300"
                    to={AuthRoutes.allianceList}
                  >
                    Allianzliste
                  </NavLink>
                </li>
                <li className="block mt-4 mr-4 half-hd:inline-block half-hd:mt-0">
                  <NavLink
                    className="text-teal-200 hover:text-orange-300"
                    to={AuthRoutes.scanList}
                  >
                    Subraum-Scans
                  </NavLink>
                </li>
                <li className="block mt-4 mr-4 half-hd:inline-block half-hd:mt-0">
                  <NavLink
                    className="text-teal-200 hover:text-orange-300"
                    to={AuthRoutes.stellarCartoraphy}
                  >
                    Stellarkartographie
                  </NavLink>
                </li>
              </ul>
            )}
            {/* above left aligned */}
            <div className="half-hd:flex-grow"></div>
            {/* below right aligned */}
            {this.isAdmin() && (
              <ul className="">
                <li className="block mt-4 mr-4 half-hd:inline-block half-hd:mt-0">
                  <NavLink
                    className="text-teal-200 hover:text-orange-300"
                    to={AuthRoutes.adminOverview}
                  >
                    Übersicht
                  </NavLink>
                </li>
                <li className="block mt-4 mr-4 half-hd:inline-block half-hd:mt-0">
                  <NavLink
                    className="text-teal-200 hover:text-orange-300"
                    to={AuthRoutes.adminUserList}
                  >
                    Benutzerliste
                  </NavLink>
                </li>
              </ul>
            )}
            <Logout {...this.props} />
          </div>
        </nav>
      </header>
    );
  }
}
