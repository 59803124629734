import React, { Component, ReactElement } from "react";
import { AuthComponentProps } from "./auth/AuthRoute.component";

export class Welcome extends Component<AuthComponentProps> {
  state: {};

  constructor(props: AuthComponentProps) {
    super(props);
    this.state = {};
  }

  render(): ReactElement {
    return (
      <section className="flex flex-col justify-center mx-auto">
        <h1 className="px-2 m-2 mb-2 text-center bg-gray-800 border border-gray-800 border-solid">
          Willkommen im internen Bereich {this.props.currentUser.name}!
        </h1>
      </section>
    );
  }
}
