import React, { Component, ReactElement } from "react";
import { RouteComponentProps } from "react-router-dom";
import { AllianceLinkWithID } from "../../components/AllianceLink.component";
import { Api } from "../../api/Api";
import { Filled, Bordered } from "../../components/Divs.component";
import {
  TDAllianceLinkWithID,
  TDPlayerLink,
  TDRight,
  TH,
} from "../../components/Tables.component";
import { throttle } from "../../helper/Helper";
import { PlayerLinkWithID } from "../../components/PlayerLink.component";
import { Player } from "./Player.interfaces";
import { DateSpan } from "../../components/DateSpan.component";

export class PlayerList extends Component<RouteComponentProps> {
  state: {
    isTooSmallForTable: boolean;
    showActiveOnly: boolean;
    isLoaded: boolean;
    error: Error | null;
    players: Player[] | undefined;
  };

  throttledWindowResize: ReturnType<typeof throttle>;

  constructor(props: RouteComponentProps) {
    super(props);
    this.state = {
      isTooSmallForTable: this.checkScreenSize(),
      showActiveOnly: false,
      isLoaded: false,
      error: null,
      players: undefined,
    };
    this.throttledWindowResize = throttle(this.handleWindowResize, 1000);
  }

  async componentDidMount() {
    window.addEventListener("resize", this.throttledWindowResize);
    await this.loadAllPlayers();
  }

  async componentWillUnmount() {
    window.removeEventListener("resize", this.throttledWindowResize);
  }

  showActivePlayers = async () => {
    this.loadActivePlayers();
  };

  showAllPlayers = async () => {
    this.loadAllPlayers();
  };

  handleWindowResize = () => {
    this.setState({ isTooSmallForTable: this.checkScreenSize() });
  };

  private async loadAllPlayers() {
    try {
      const players = await Api.getInstance().fetchPlayers();
      this.setState({
        players: players,
        showActiveOnly: false,
        isLoaded: true,
        error: null,
      });
    } catch (error) {
      console.error(error);
      this.setState({
        players: undefined,
        showActiveOnly: false,
        isLoaded: false,
        error: error,
      });
    }
  }

  private async loadActivePlayers() {
    try {
      const players = await Api.getInstance().fetchActivePlayers();
      this.setState({
        players: players,
        showActiveOnly: true,
        isLoaded: true,
        error: null,
      });
    } catch (error) {
      console.error(error);
      this.setState({
        players: undefined,
        showActiveOnly: true,
        isLoaded: false,
        error: error,
      });
    }
  }

  private checkScreenSize() {
    return window.innerWidth < 1244;
  }

  render(): ReactElement {
    if (!this.state.isLoaded && this.state.error === null) {
      return <h1>Loading...</h1>;
    }
    if (this.state.error) {
      return <h1>{this.state.error.toString()}</h1>;
    }
    if (!this.state.players) {
      return <h1>Could not retrieve data</h1>;
    }

    let playerList: ReactElement;
    if (this.state.isTooSmallForTable) {
      playerList = (
        <div className="flex flex-col px-2 justify-evenly">
          {this.state.players.map((player: Player) => (
            <div className="flex-grow max-w-xl p-2 mx-auto mt-4 bg-gray-700 border-4 border-gray-700 rounded-lg">
              <div className="grid grid-cols-4 gap-1 p-1 bg-black">
                <Filled>Name</Filled>
                <Bordered className="col-span-3 text-center">
                  <PlayerLinkWithID player={player} />
                </Bordered>
                <Filled>Allianz</Filled>
                <Bordered className="col-span-3 text-center">
                  {player.alliance ? (
                    <AllianceLinkWithID alliance={player.alliance} />
                  ) : (
                    "-"
                  )}
                </Bordered>
                <Filled>Ansehen</Filled>
                <Bordered className="text-right">{player.reputation}</Bordered>
                <Filled className="col-span-2">Punkte</Filled>
                <Filled>Level</Filled>
                <Bordered className="text-right">{player.level}</Bordered>
                <Filled>Wissenschaft</Filled>
                <Bordered className="text-right">
                  {player.points.research}
                </Bordered>
                <Filled>Runden</Filled>
                <Bordered className="text-right">{player.ticks}</Bordered>
                <Filled>Geselschaft</Filled>
                <Bordered className="text-right">
                  {player.points.society}
                </Bordered>
                <Filled>Aktualisiert</Filled>
                <Bordered className="text-right">
                  <DateSpan date={player.updatedAt} />
                </Bordered>
                <Filled>Wirtschaft</Filled>
                <Bordered className="text-right">
                  {player.points.economy}
                </Bordered>
                <Filled>Erstellt</Filled>
                <Bordered className="text-right">
                  <DateSpan date={player.createdAt} />
                </Bordered>
                <Filled>Militär</Filled>
                <Bordered className="text-right">
                  {player.points.military}
                </Bordered>
              </div>
            </div>
          ))}
        </div>
      );
    } else {
      playerList = (
        <table className="table mt-4 border-separate">
          <thead className="">
            <tr className="">
              <TH>Name</TH>
              <TH>Id</TH>
              <TH>Ansehen</TH>
              <TH>Level</TH>
              <TH>Runden</TH>
              <TH>Allianz</TH>
              <TH>Wissenschaft</TH>
              <TH>Geselschaft</TH>
              <TH>Wirtschaft</TH>
              <TH>Militär</TH>
              <TH>Aktualisiert</TH>
              <TH>Erstellt</TH>
            </tr>
          </thead>
          <tbody>
            {this.state.players.map((player: Player) => (
              <tr key={player.id}>
                <TDPlayerLink player={player} />
                <TDRight>{player.id}</TDRight>
                <TDRight>{player.reputation}</TDRight>
                <TDRight>{player.level}</TDRight>
                <TDRight>{player.ticks}</TDRight>
                <TDAllianceLinkWithID alliance={player.alliance} />
                <TDRight>{player.points.research}</TDRight>
                <TDRight>{player.points.society}</TDRight>
                <TDRight>{player.points.economy}</TDRight>
                <TDRight>{player.points.military}</TDRight>
                <TDRight>
                  <DateSpan date={player.updatedAt} />
                </TDRight>
                <TDRight>
                  <DateSpan date={player.createdAt} />
                </TDRight>
              </tr>
            ))}
          </tbody>
        </table>
      );
    }

    return (
      <section className="flex flex-col px-2 justify-evenly">
        <div className="flex flex-row flex-wrap justify-center flex-grow mt-2">
          {this.state.showActiveOnly ? (
            <button
              className="inline-block px-4 py-2 mx-1 leading-none text-white bg-gray-800 border border-white rounded hover:border-orange-300 hover:text-orange-300"
              onClick={this.showAllPlayers}
            >
              Alle anzeigen
            </button>
          ) : (
            <button
              className="inline-block px-4 py-2 mx-1 leading-none text-white bg-gray-800 border border-white rounded hover:border-orange-300 hover:text-orange-300"
              onClick={this.showActivePlayers}
            >
              Nur aktive anzeigen
            </button>
          )}
        </div>
        {playerList}
      </section>
    );
  }
}
