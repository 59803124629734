import React, { Component, ReactElement } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Api } from "../../api/Api";
import { Globals } from "../../Globals";
import {
  TH,
  TDCenter,
  TDName,
  TDRight,
  TDLeft,
  TDPlayerLinkWithID,
} from "../../components/Tables.component";
import { PlayerMinimal } from "../player/Player.interfaces";
import { Scan, ScanObject } from "./Scan.interfaces";
import { UserLinkWithId } from "../../components/UserLink.component";
import { DateSpan } from "../../components/DateSpan.component";
import { UserMinimal } from "../admin/users/User.interface";

interface MatchParams {
  id: string | undefined;
}

interface TableRow {
  id: number;
  x: number;
  y: number;
  image: string;
  name: string;
  ncc: string;
  objectClass: string;
  huell: string;
  shields: string;
  player: PlayerMinimal;
  updatedBy: UserMinimal;
  updatedAt: Date;
  createdBy: UserMinimal;
  createdAt: Date;
}

export class ScanDetail extends Component<RouteComponentProps<MatchParams>> {
  state: {
    isLoaded: boolean;
    error: Error | null;
    scanId: number;
    data: Scan | undefined;
  };
  constructor(props: RouteComponentProps<MatchParams>) {
    super(props);
    if (!this.props.match.params.id) {
      throw new Error("No id provided");
    }
    const scanId = Number.parseInt(this.props.match.params.id);
    this.state = {
      isLoaded: false,
      error: null,
      scanId: scanId,
      data: undefined,
    };
  }

  async componentDidMount() {
    try {
      const data = await Api.getInstance().fetchScan(this.state.scanId);
      console.log("mount data: ", data);
      this.setState({
        data: data,
        isLoaded: true,
        error: null,
      });
    } catch (error) {
      console.error(error);
      this.setState({
        data: undefined,
        isLoaded: false,
        error: error,
      });
    }
  }

  private getColonies(scanObjects: ScanObject[]): TableRow[] {
    return scanObjects
      .filter((scanObject) => scanObject.objectClass === "Kolonie")
      .map((colony: ScanObject) => {
        return {
          id: colony.id,
          x: colony.x,
          y: colony.y,
          image: `${Globals.imageUrl}/planets/${colony.image}.gif`,
          name: colony.name,
          ncc: "-",
          objectClass: colony.objectClass,
          huell: "-",
          shields: "-",
          player: colony.owner,
          updatedBy: colony.updatedBy,
          updatedAt: colony.updatedAt,
          createdBy: colony.createdBy,
          createdAt: colony.createdAt,
        };
      });
  }

  private getShips(scanObjects: ScanObject[]): TableRow[] {
    return scanObjects
      .filter((scanObject) => scanObject.objectClass !== "Kolonie")
      .map((ship) => {
        return {
          id: ship.id,
          x: ship.x,
          y: ship.y,
          image: `${Globals.imageUrl}/ships/${ship.image}.gif`,
          name: ship.name,
          ncc: ship.ncc,
          objectClass: ship.objectClass,
          huell: ship.shieldsActive ? "-" : `${ship.huell}/${ship.huellMax}`,
          shields: ship.shieldsActive
            ? `${ship.shields}/${ship.shieldsMax}`
            : "-",
          player: ship.owner,
          updatedBy: ship.updatedBy,
          updatedAt: ship.updatedAt,
          createdBy: ship.createdBy,
          createdAt: ship.createdAt,
        };
      });
  }

  render(): ReactElement {
    if (!this.state.isLoaded && this.state.error === null) {
      return <h1>Loading...</h1>;
    }
    if (this.state.error) {
      return <h1>{this.state.error.toString()}</h1>;
    }
    if (!this.state.data) {
      return <h1>Could not retrieve data</h1>;
    }
    let tableRows: TableRow[] = [];
    tableRows = tableRows.concat(this.getColonies(this.state.data.scanObjects));
    tableRows = tableRows.concat(this.getShips(this.state.data.scanObjects));
    return (
      <section className="flex flex-col justify-center mx-auto">
        <div className="flex flex-row p-1 m-2 mb-2 text-left border border-gray-800 border-solid ">
          <span className="flex-grow bg-gray-800"></span>
          <span className="p-1 ml-1 bg-gray-800 border border-gray-800 border-solid">
            Teleskop-Report
          </span>
          <span className="p-1 ml-1 bg-gray-800 border border-gray-800 border-solid">
            System
          </span>
          <span className="w-20 p-1 mr-1 text-center bg-black border border-gray-800 border-solid">
            {this.state.data.x + "|" + this.state.data.y}
          </span>
          <span className="p-1 bg-gray-800 border border-gray-800 border-solid">
            Objekte
          </span>
          <span className="w-16 p-1 mr-1 text-center bg-black border border-gray-800 border-solid">
            {this.state.data.scanObjects.length}
          </span>
          <span className="p-1 bg-gray-800 border border-gray-800 border-solid">
            Aktualisiert am
          </span>
          <DateSpan
            className="p-1 bg-black border border-gray-800 border-solid"
            date={this.state.data.updatedAt}
          />
          <span className="p-1 bg-gray-800 border border-gray-800 border-solid">
            von
          </span>
          <span className="p-1 mr-1 bg-black border border-gray-800 border-solid">
            <UserLinkWithId user={this.state.data.updatedBy} />
          </span>
          <span className="p-1 bg-gray-800 border border-gray-800 border-solid">
            Erstellt am
          </span>
          <DateSpan
            className="p-1 bg-black border border-gray-800 border-solid"
            date={this.state.data.createdAt}
          />
          <span className="p-1 bg-gray-800 border border-gray-800 border-solid">
            von
          </span>
          <span className="p-1 mr-1 bg-black border border-gray-800 border-solid">
            <UserLinkWithId user={this.state.data.createdBy} />
          </span>
          <span className="flex-grow bg-gray-800"></span>
        </div>

        <table className="table mx-auto border-separate">
          <thead className="">
            <tr>
              <TH>Bild</TH>
              <TH>Name</TH>
              <TH>NCC</TH>
              <TH>Klasse</TH>
              <TH>Hülle</TH>
              <TH>Schilde</TH>
              <TH>Von</TH>
              <TH>Aktualisiert am</TH>
              <TH>Aktualisiert von</TH>
              <TH>Erstellt am</TH>
              <TH>Erstellt von</TH>
            </tr>
          </thead>
          <tbody className="color-default">
            {tableRows.map((row: TableRow) => (
              <tr key={row.id}>
                <TDCenter>
                  <img src={row.image} alt={row.objectClass} />
                </TDCenter>
                <TDName name={row.name} />
                <TDRight>{row.ncc}</TDRight>
                <TDLeft>{row.objectClass}</TDLeft>
                <TDCenter>{row.huell}</TDCenter>
                <TDCenter>{row.shields}</TDCenter>
                <TDPlayerLinkWithID player={row.player} />
                <TDRight>
                  <DateSpan date={row.updatedAt} />
                </TDRight>
                <TDRight>
                  <UserLinkWithId user={row.updatedBy} />
                </TDRight>
                <TDRight>
                  <DateSpan date={row.createdAt} />
                </TDRight>
                <TDRight>
                  <UserLinkWithId user={row.createdBy} />
                </TDRight>
              </tr>
            ))}
          </tbody>
        </table>
      </section>
    );
  }
}
