import React, { Component, FormEvent, ReactElement } from "react";
import { DateSpan } from "../../components/DateSpan.component";
import { Scan } from "../scan/Scan.interfaces";

interface SectorDetailsState {
  input: {
    changed: boolean;
    x: number;
    y: number;
  };
}

export interface SectorHeaderProps {
  x: number;
  y: number;
  scan: Scan | undefined;
  onSubmit(x: number, y: number): any;
}

export class SectorHeader extends Component<SectorHeaderProps> {
  state: SectorDetailsState;

  constructor(props: SectorHeaderProps) {
    super(props);
    this.state = {
      input: {
        changed: false,
        x: this.props.x,
        y: this.props.y,
      },
    };
    this.handleChangeX = this.handleChangeX.bind(this);
    this.handleChangeY = this.handleChangeY.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  static getDerivedStateFromProps(
    props: SectorHeaderProps,
    current_state: SectorDetailsState
  ) {
    if (
      !current_state.input.changed &&
      (current_state.input.x !== props.x || current_state.input.y !== props.y)
    ) {
      return {
        input: {
          changed: false,
          x: props.x,
          y: props.y,
        },
      };
    }
    return null;
  }

  async handleChangeX(event: FormEvent<HTMLInputElement>) {
    this.setState({
      input: {
        changed: true,
        x: Number.parseInt(event.currentTarget.value),
        y: this.state.input.y,
      },
    });
  }

  async handleChangeY(event: FormEvent<HTMLInputElement>) {
    this.setState({
      input: {
        changed: true,
        x: this.state.input.x,
        y: Number.parseInt(event.currentTarget.value),
      },
    });
  }

  async handleSubmit(event: FormEvent<HTMLFormElement>): Promise<void> {
    event.preventDefault();
    this.setState({
      input: {
        changed: false,
        x: this.state.input.x,
        y: this.state.input.y,
      },
    });
    this.props.onSubmit(this.state.input.x, this.state.input.y);
  }

  render(): ReactElement {
    return (
      <form onSubmit={this.handleSubmit} noValidate={true}>
        <div className="flex flex-row p-1 text-left border border-gray-800 border-solid">
          <span className="p-1 bg-gray-800 border border-gray-800 border-solid">
            System
          </span>
          <span className="w-20 p-1 mr-1 text-center bg-black border border-gray-800 border-solid">
            {this.props.x + "|" + this.props.y}
          </span>
          <span className="p-1 bg-gray-800 border border-gray-800 border-solid">
            Objekte
          </span>
          <span className="w-16 p-1 mr-1 text-center bg-black border border-gray-800 border-solid">
            {this.props.scan ? this.props.scan.scanObjects.length : 0}
          </span>
          {this.props.scan && (
            <span className="p-1 bg-gray-800 border border-gray-800 border-solid">
              Aktualisiert
            </span>
          )}
          {this.props.scan && (
            <DateSpan
              className="p-1 mr-1 bg-black border border-gray-800 border-solid"
              date={this.props.scan.updatedAt}
            />
          )}
          {this.props.scan && (
            <span className="p-1 bg-gray-800 border border-gray-800 border-solid">
              Erstellt
            </span>
          )}
          {this.props.scan && (
            <DateSpan
              className="p-1 mr-1 bg-black border border-gray-800 border-solid"
              date={this.props.scan.createdAt}
            />
          )}

          <span className="flex-grow bg-gray-800"></span>

          <label className="px-2 py-1 ml-1 bg-gray-800 border border-gray-800 border-solid">
            X
          </label>
          <input
            className="w-12 px-2 py-1 mr-1 text-center text-black bg-gray-700 border-2 border-gray-500 border-solid focus:border-orange-300"
            type="text"
            name="x"
            value={this.state.input.x}
            onChange={this.handleChangeX}
          />

          <label className="px-2 py-1 bg-gray-800 border border-gray-800 border-solid">
            Y
          </label>
          <input
            className="w-12 px-2 py-1 mr-1 text-center text-black bg-gray-700 border-2 border-gray-500 border-solid focus:border-orange-300"
            type="text"
            name="y"
            value={this.state.input.y}
            onChange={this.handleChangeY}
          />
          <button className="px-2 py-1 mr-1 bg-gray-800 border border-white border-solid hover:border-orange-300 hover:text-orange-300">
            Anzeigen
          </button>
        </div>
      </form>
    );
  }
}
