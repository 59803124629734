import React, { Component, ReactElement } from 'react';
import { Api } from '../../api/Api';
import {
  TDAllianceLink,
  TDPlayerLinkWithID,
  TDRight,
  TH,
} from '../../components/Tables.component';
import { Alliance } from './Alliance.interface';

export interface AllianceListProps {}

export class AllianceList extends Component<AllianceListProps> {
  state: {
    isLoaded: boolean;
    error: Error | null;
    data: Alliance[] | undefined;
  };

  constructor(props: AllianceListProps) {
    super(props);
    this.state = {
      isLoaded: false,
      error: null,
      data: undefined,
    };
  }

  async componentDidMount() {
    try {
      const data = await Api.getInstance().fetchAlliances();
      this.setState({
        data: data,
        isLoaded: true,
        error: null,
      });
    } catch (error) {
      console.error(error);
      this.setState({
        data: undefined,
        isLoaded: false,
        error: error,
      });
    }
  }

  render(): ReactElement {
    if (!this.state.isLoaded && this.state.error === null) {
      return <h1>Loading...</h1>;
    }
    if (this.state.error) {
      return <h1>{this.state.error.toString()}</h1>;
    }
    if (!this.state.data) {
      return <h1>Could not retrieve data</h1>;
    }
    return (
      <section className="container flex justify-center w-full px-2 mx-auto">
        <table className="table border-separate">
          <thead className="">
            <tr className="">
              <TH>Id</TH>
              <TH>Name</TH>
              <TH>President</TH>
              <TH>Members</TH>
            </tr>
          </thead>
          <tbody>
            {this.state.data.map((alliance: Alliance) => (
              <tr key={alliance.id}>
                <TDRight>
                  <a href={'/alliance/' + alliance.id}>{alliance.id}</a>
                </TDRight>
                <TDAllianceLink alliance={alliance} />
                <TDPlayerLinkWithID player={alliance.president} />
                <TDRight>{alliance.members}</TDRight>
              </tr>
            ))}
          </tbody>
        </table>
      </section>
    );
  }
}
