import React, { Component } from "react";
import { Api } from "../api/Api";
import { CurrentUser, NonAuthRoutes } from "../views/App";
import { UserRoles } from "../Globals";
import { RouteComponentProps, withRouter } from "react-router-dom";

export interface LogoutProps extends RouteComponentProps {
  currentUser: CurrentUser;
}

class Logout extends Component<LogoutProps> {
  onLogoutClick = async () => {
    console.log("Logout clicked");
    Api.getInstance().setCredentials("", "");
    let currentUser = this.props.currentUser;
    currentUser.name = "";
    currentUser.token = "";
    currentUser.roles = [UserRoles.none];
    currentUser.updateCurrentUser(currentUser);
    this.props.history.push(NonAuthRoutes.login);
  };

  render() {
    return (
      <button
        onClick={this.onLogoutClick}
        className="inline-block px-4 py-2 mt-4 leading-none text-white border border-white rounded half-hd:mt-0 hover:border-orange-300 hover:text-orange-300"
      >
        Abmelden
      </button>
    );
  }
}

export default withRouter(Logout);
