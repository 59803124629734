import React, { Component, ReactElement } from "react";
import { Globals } from "../Globals";
import { ScanObject } from "../views/scan/Scan.interfaces";
import { DateSpan } from "./DateSpan.component";
import { TD, TDCenter, TDNameXS, TDRight, TH } from "./Tables.component";

export interface ColoniesListProps {
  colonies: ScanObject[];
}

export class ColoniesList extends Component<ColoniesListProps> {
  render(): ReactElement {
    if (!this.props.colonies) {
      throw Error("Colonies not loaded");
    }
    return (
      <table className="table border-separate">
        <thead className="">
          <tr className="">
            <TH>Position</TH>
            <TH>Bild</TH>
            <TH>Name</TH>
            <TH>Aktualisiert</TH>
            <TH>Erstellt</TH>
          </tr>
        </thead>
        <tbody>
          {this.props.colonies.map((colony) => (
            <tr key={colony.x + "|" + colony.y}>
              <TDCenter>{colony.x + "|" + colony.y}</TDCenter>
              <TD>
                <img
                  alt="Kolonie"
                  src={`${Globals.imageUrl}/planets/${colony.image}.gif`}
                />
              </TD>
              <TDNameXS name={colony.name} />
              <TDRight>
                <DateSpan date={colony.updatedAt} />
              </TDRight>
              <TDRight>
                <DateSpan date={colony.createdAt} />
              </TDRight>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }
}
