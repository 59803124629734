import React, { Component, ReactElement } from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import { NonAuthRoutes } from "./App";

export class Home extends Component<RouteComponentProps> {
  state: {};

  constructor(props: RouteComponentProps) {
    super(props);
    this.state = {};
  }

  render(): ReactElement {
    return (
      <section className="flex flex-col max-w-full p-2 mt-8">
        <h1 className="flex flex-row items-center justify-center p-2 text-center bg-gray-800 border-4 border-gray-700 border-solid">
          <img
            className="w-8 h-8 mr-2 fill-current"
            src="/favicon.ico"
            alt="Logo"
          />
          <span>STC2-DB - Willkommen</span>
        </h1>
        <div className="flex flex-col p-2 mx-auto mt-8">
          <Link
            className="px-4 py-1 mx-auto mt-2 text-center bg-gray-800 border border-gray-500 border-solid rounded-md hover:border-orange-300 hover:text-orange-300"
            to={NonAuthRoutes.login}
          >
            Login
          </Link>
        </div>
      </section>
    );
  }
}
