import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./App.css";
import { Overview } from "./admin/overview/Overview.component";
import { PlayerList } from "./player/PlayerList.component";
import { PlayerDetail } from "./player/PlayerDetail.component";
import { AllianceList } from "./alliance/AllianceList.component";
import { AllianceDetail } from "./alliance/AllianceDetail.component";
import { ScanList } from "./scan/ScanList.component";
import { ScanDetail } from "./scan/ScanDetail.component";
import { StellarCartography } from "./stellar-cartography/StellarCartography.component";
import { AuthRoute } from "./auth/AuthRoute.component";
import { Navigation } from "../components/Navigation.component.";
import { Login } from "./auth/Login.component";
import { Unauthorized } from "./auth/Unauthorized.component";
import { UserRoles } from "../Globals";
import { UserList } from "./admin/users/UserList.component";
import { UserDetail } from "./admin/users/UserDetail.component";
import { UserCreate } from "./admin/users/UserCreate.component";
import { Home } from "./Home.component";
import { Welcome } from "./Welcome.component";

export enum AuthRoutes {
  welcome = "/welcome",
  playerList = "/player/list",
  playerDetail = "/player/:id",
  allianceList = "/alliance/list",
  allianceDetail = "/alliance/:id",
  scanList = "/scan/list",
  scanDetail = "/scan/:id",
  stellarCartoraphy = "/stellar-cartography",
  stellarCartoraphyExact = "/stellar-cartography/:y/:x",
  adminOverview = "/admin/overview",
  adminUserList = "/admin/user/list",
  adminUserCreate = "/admin/user/create",
  adminUserDetail = "/admin/user/:id",
}

export enum NonAuthRoutes {
  home = "/",
  login = "/login",
  unauthorized = "/unauthorized",
}
export interface CurrentUser {
  name: string;
  token: string;
  roles: UserRoles[];
  updateCurrentUser(changedUser: CurrentUser): any;
}

export interface AppProps {}

export class App extends Component<AppProps> {
  state: {
    currentUser: CurrentUser;
  };

  constructor(props: AppProps) {
    super(props);
    // TODO: Maybe recreate state from local storage?
    this.state = {
      currentUser: {
        name: "",
        token: "",
        roles: [UserRoles.none],
        updateCurrentUser: this.updateCurrentUser,
      },
    };
  }

  updateCurrentUser = async (changedUser: CurrentUser) => {
    this.setState({ currentUser: changedUser });
  };

  render() {
    return (
      <Router>
        {this.state.currentUser.token && (
          <Navigation currentUser={this.state.currentUser} />
        )}
        <Switch>
          <Route
            path={NonAuthRoutes.login}
            render={(props) => (
              <Login {...props} currentUser={this.state.currentUser} />
            )}
          />
          <Route path={NonAuthRoutes.unauthorized} component={Unauthorized} />
          <AuthRoute
            Component={Welcome}
            path={AuthRoutes.welcome}
            requiredRole={UserRoles.none}
            currentUser={this.state.currentUser}
          />
          <AuthRoute
            Component={PlayerList}
            path={AuthRoutes.playerList}
            requiredRole={UserRoles.user}
            currentUser={this.state.currentUser}
          />
          <AuthRoute
            Component={PlayerDetail}
            path={AuthRoutes.playerDetail}
            requiredRole={UserRoles.user}
            currentUser={this.state.currentUser}
          />
          <AuthRoute
            Component={AllianceList}
            path={AuthRoutes.allianceList}
            requiredRole={UserRoles.user}
            currentUser={this.state.currentUser}
          />
          <AuthRoute
            Component={AllianceDetail}
            path={AuthRoutes.allianceDetail}
            requiredRole={UserRoles.user}
            currentUser={this.state.currentUser}
          />
          <AuthRoute
            Component={ScanList}
            path={AuthRoutes.scanList}
            requiredRole={UserRoles.user}
            currentUser={this.state.currentUser}
          />
          <AuthRoute
            Component={ScanDetail}
            path={AuthRoutes.scanDetail}
            requiredRole={UserRoles.user}
            currentUser={this.state.currentUser}
          />
          <AuthRoute
            Component={StellarCartography}
            path={AuthRoutes.stellarCartoraphyExact}
            requiredRole={UserRoles.user}
            currentUser={this.state.currentUser}
          />
          <AuthRoute
            Component={StellarCartography}
            path={AuthRoutes.stellarCartoraphy}
            requiredRole={UserRoles.user}
            currentUser={this.state.currentUser}
          />
          <AuthRoute
            Component={Overview}
            path={AuthRoutes.adminOverview}
            requiredRole={UserRoles.admin}
            currentUser={this.state.currentUser}
          />
          <AuthRoute
            Component={UserList}
            path={AuthRoutes.adminUserList}
            requiredRole={UserRoles.admin}
            currentUser={this.state.currentUser}
          />
          <AuthRoute
            Component={UserCreate}
            path={AuthRoutes.adminUserCreate}
            requiredRole={UserRoles.admin}
            currentUser={this.state.currentUser}
          />
          <AuthRoute
            Component={UserDetail}
            path={AuthRoutes.adminUserDetail}
            requiredRole={UserRoles.admin}
            currentUser={this.state.currentUser}
          />
          <Route path={NonAuthRoutes.home} component={Home} />
        </Switch>
      </Router>
    );
  }
}
