import React, { Component, ReactElement } from "react";
import { AllianceLink, AllianceLinkWithID } from "./AllianceLink.component";
import { AllianceMinimal } from "../views/alliance/Alliance.interface";
import { PlayerLink, PlayerLinkWithID } from "./PlayerLink.component";
import { PlayerMinimal } from "../views/player/Player.interfaces";

export interface TableElementProps {
  onClick?: any;
}
export class TH extends Component<TableElementProps> {
  render(): ReactElement {
    return (
      <th
        onClick={this.props.onClick}
        className="px-2 bg-gray-800 border border-gray-800 border-solid"
      >
        {this.props.children}
      </th>
    );
  }
}

export class TD extends Component<TableElementProps> {
  render(): ReactElement {
    return (
      <td className="px-2 border border-gray-800 border-solid">
        {this.props.children}
      </td>
    );
  }
}

export class TDCenter extends Component<TableElementProps> {
  render(): ReactElement {
    return (
      <td className="px-2 text-center border border-gray-800 border-solid">
        {this.props.children}
      </td>
    );
  }
}

export class TDRight extends Component<TableElementProps> {
  render(): ReactElement {
    return (
      <td className="px-2 text-right border border-gray-800 border-solid">
        {this.props.children}
      </td>
    );
  }
}

export class TDLeft extends Component<TableElementProps> {
  render(): ReactElement {
    return (
      <td className="px-2 text-right border border-gray-800 border-solid">
        {this.props.children}
      </td>
    );
  }
}

export interface TDNameProps extends TableElementProps {
  name: string;
}
export class TDName extends Component<TDNameProps> {
  render(): ReactElement {
    return (
      <td
        className="px-2 text-left border border-gray-800 border-solid break-word"
        dangerouslySetInnerHTML={{ __html: this.props.name }}
      ></td>
    );
  }
}

export class TDNameXS extends Component<TDNameProps> {
  render(): ReactElement {
    return (
      <td
        className="max-w-xs px-2 text-right border border-gray-800 border-solid break-word"
        dangerouslySetInnerHTML={{ __html: this.props.name }}
      ></td>
    );
  }
}

export interface TDPlayerLinkProps extends TableElementProps {
  player: PlayerMinimal;
}
export class TDPlayerLinkWithID extends Component<TDPlayerLinkProps> {
  render(): ReactElement {
    return (
      <td className="px-2 text-right border border-gray-800 border-solid break-word">
        <PlayerLinkWithID player={this.props.player}></PlayerLinkWithID>
      </td>
    );
  }
}

export class TDPlayerLink extends Component<TDPlayerLinkProps> {
  render(): ReactElement {
    return (
      <td className="px-2 text-left border border-gray-800 border-solid break-word">
        <PlayerLink player={this.props.player}></PlayerLink>
      </td>
    );
  }
}

export interface TDAllianceWithIDLinkProps extends TableElementProps {
  alliance?: AllianceMinimal;
}
export class TDAllianceLinkWithID extends Component<TDAllianceWithIDLinkProps> {
  render(): ReactElement {
    return (
      <td className="px-2 text-left border border-gray-800 border-solid break-word">
        {this.props.alliance ? (
          <AllianceLinkWithID alliance={this.props.alliance} />
        ) : (
          "-"
        )}
      </td>
    );
  }
}
export interface TDAllianceLinkProps extends TableElementProps {
  alliance: AllianceMinimal;
}

export class TDAllianceLink extends Component<TDAllianceLinkProps> {
  render(): ReactElement {
    return (
      <td className="px-2 text-left border border-gray-800 border-solid break-word">
        <AllianceLink alliance={this.props.alliance} />
      </td>
    );
  }
}
