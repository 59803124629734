import React, { ChangeEvent, Component, FormEvent, ReactElement } from "react";
import { RouteComponentProps } from "react-router";
import { Api } from "../../../api/Api";
import { DateSpan } from "../../../components/DateSpan.component";
import { Bordered, Filled } from "../../../components/Divs.component";
import { UserRoles } from "../../../Globals";
import { User } from "./User.interface";

export interface MatchParams {
  id: string;
}

interface Validation {
  field: string;
  error: string;
}

enum Fields {
  name,
  passwort1,
  passwort2,
  playerId,
  roles,
  notes,
}

export class UserDetail extends Component<RouteComponentProps<MatchParams>> {
  state: {
    // Param
    userId: number;
    // Internal state
    isLoaded: boolean;
    error: Error | null;
    validation: Validation[];
    // Lodaded Data
    user: User | undefined;
    passwort1: string;
    passwort2: string;
  };

  constructor(props: RouteComponentProps<MatchParams>) {
    super(props);
    if (!("id" in this.props.match.params)) {
      throw new Error("No id provided");
    }
    const userId = Number.parseInt(this.props.match.params.id);
    this.state = {
      userId: userId,
      isLoaded: false,
      error: null,
      validation: [],
      user: undefined,
      passwort1: "",
      passwort2: "",
    };
  }

  async loadUser() {
    try {
      const user = await Api.getInstance().fetchUser(this.state.userId);
      console.log(user);
      await this.setState({
        user: user,
        isLoaded: true,
        error: null,
      });
    } catch (error) {
      console.error(error);
      await this.setState({
        user: undefined,
        isLoaded: false,
        error: error,
      });
    }
  }

  async componentDidMount() {
    await this.loadUser();
  }

  onSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    console.log("Save clicked");
    try {
      if (this.state.validation.length !== 0) {
        console.error("Speichern nicht möglich: Validierung fehlgeschlagen");
        return;
      }
      const updateUser = this.state.user;
      if (!updateUser) {
        console.error("Speichern nicht möglich: User noch nicht geladen");
        return;
      }
      if (
        this.state.passwort1 !== "" &&
        this.state.passwort1 === this.state.passwort2
      ) {
        updateUser.password = this.state.passwort1;
      }
      console.log(updateUser);
      let savedUser = await Api.getInstance().postUpdateUser(updateUser);
      console.log(savedUser);
      this.setState({
        user: savedUser,
      });
    } catch (error) {
      console.error(error);
      this.setState({ error: error });
    }
  };

  onReset = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    console.log("Reset clicked");
    await this.loadUser();
    await this.setState({
      validation: [],
      passwort1: "",
      passwort2: "",
    });
  };

  onChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const user = this.state.user;
    if (user) {
      switch (event.currentTarget.name) {
        case Fields.name.toString():
          user.name = event.currentTarget.value;
          break;
        case Fields.playerId.toString():
          user.playerId = Number.parseInt(event.currentTarget.value);
          break;
        case Fields.passwort1.toString():
          await this.setState({ passwort1: event.currentTarget.value });
          await this.passwortValidation();
          break;
        case Fields.passwort2.toString():
          await this.setState({ passwort2: event.currentTarget.value });
          await this.passwortValidation();
          break;
        default:
          break;
      }
      await this.setState({
        user: user,
      });
    }
  };

  private async passwortValidation() {
    // Delete old validations
    const validation = this.state.validation.filter(
      (val) =>
        val.field === Fields.passwort1.toString() ||
        val.field === Fields.passwort2.toString()
    );
    // Check passwort
    if (this.state.passwort1 !== this.state.passwort2) {
      validation.push({
        field: "passwort1",
        error: "Passwörter stimmen nicht überein",
      });
    }
    // Save new validations
    await this.setState({
      validation: validation,
    });
  }

  onSelectChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const user = this.state.user;
    if (user) {
      const allRoles = Object.values(UserRoles);

      let selectedOptions = Array.from(
        event.target.selectedOptions,
        (option) => option.value
      );

      // Map strings to UserRoles
      let roles = selectedOptions.map(
        (option) =>
          allRoles.find((role) => role.toString() === option) || UserRoles.none
      );

      // Remove duplicates
      roles = roles.filter(function (item, pos) {
        return roles.indexOf(item) === pos;
      });

      user.roles = roles;
      this.setState({
        user: user,
      });
    }
  };

  onTextAreaChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const user = this.state.user;
    if (user) {
      user.note = event.target.value;
      this.setState({
        user: user,
      });
    }
  };

  render(): ReactElement {
    if (!this.state.isLoaded && this.state.error === null) {
      return <h1>Loading...</h1>;
    }
    if (this.state.error) {
      return <h1>{this.state.error.toString()}</h1>;
    }
    if (!this.state.user) {
      return <h1>Could not retrieve data</h1>;
    }
    return (
      <section className="flex flex-col justify-center">
        <h1 className="px-2 m-2 mb-2 text-center bg-gray-800 border border-gray-800 border-solid">
          {this.state.user.name} ({this.state.user.id})
        </h1>

        {this.state.validation.map((val) => (
          <h2 className="px-2 m-2 text-center bg-gray-800 border-4 border-red-800 border-solid">
            {val.error}
          </h2>
        ))}

        <form
          onSubmit={this.onSubmit}
          onReset={this.onReset}
          noValidate={true}
          className="grid grid-cols-4 gap-1 p-1 mx-auto mt-8 border-4 border-gray-700 border-solid"
        >
          <Filled>Id</Filled>
          <Bordered className="col-span-3">{this.state.user.id}</Bordered>

          <Filled>Aktualisiert</Filled>
          <Bordered className="col-span-3">
            <DateSpan date={this.state.user.updatedAt} />
          </Bordered>

          <Filled>Erstellt</Filled>
          <Bordered className="col-span-3">
            <DateSpan date={this.state.user.createdAt} />
          </Bordered>

          <Filled>Name</Filled>
          <input
            name={Fields.name.toString()}
            type="text"
            placeholder="Name"
            className="w-full col-span-3 px-2 text-left text-black bg-gray-700 border-2 border-gray-500 border-solid focus:border-orange-300"
            value={this.state.user.name}
            onChange={this.onChange}
          />

          <Filled>Passwort</Filled>
          <input
            name={Fields.passwort1.toString()}
            type="text"
            placeholder="Passwort"
            className="w-full col-span-3 px-2 text-left text-black bg-gray-700 border-2 border-gray-500 border-solid focus:border-orange-300"
            value={this.state.passwort1}
            onChange={this.onChange}
          />

          <Filled>Passwort wiederholung</Filled>
          <input
            name={Fields.passwort2.toString()}
            type="text"
            placeholder="Passwort wiederholung"
            className="w-full col-span-3 px-2 text-left text-black bg-gray-700 border-2 border-gray-500 border-solid focus:border-orange-300"
            value={this.state.passwort2}
            onChange={this.onChange}
          />

          <Filled>Spieler-Id</Filled>
          <input
            name={Fields.playerId.toString()}
            type="number"
            placeholder="Spieler-Id"
            className="w-full col-span-3 px-2 text-left text-black bg-gray-700 border-2 border-gray-500 border-solid focus:border-orange-300"
            value={this.state.user.playerId || ""}
            onChange={this.onChange}
          />

          <Filled>Rollen</Filled>
          <select
            multiple={true}
            name={Fields.roles.toString()}
            className="w-full col-span-3 px-2 text-left text-black bg-gray-700 border-2 border-gray-500 border-solid focus:border-orange-300"
            value={this.state.user.roles}
            onChange={this.onSelectChange}
          >
            {Object.values(UserRoles).map((role: string) => (
              <option value={role}>{role}</option>
            ))}
          </select>

          <Filled>Notizen</Filled>
          <textarea
            rows={5}
            cols={40}
            name={Fields.notes.toString()}
            placeholder="Keine Notiz gesetzt"
            className="w-full col-span-3 px-2 text-left text-black bg-gray-700 border-2 border-gray-500 border-solid focus:border-orange-300"
            value={this.state.user.note}
            onChange={this.onTextAreaChange}
          />
          <div></div>
          <div className="col-span-3">
            <button
              type="submit"
              disabled={this.state.validation.length !== 0}
              className="w-32 py-1 mt-1 bg-gray-800 border border-gray-500 border-solid rounded-md disabled:opacity-50 hover:border-orange-300 hover:text-orange-300"
            >
              {/* TODO: Ausgrauen wenn Disabled! */}
              Speichern
            </button>
            <button
              type="reset"
              className="w-32 py-1 mt-1 ml-2 bg-gray-800 border border-gray-500 border-solid rounded-md hover:border-orange-300 hover:text-orange-300"
            >
              Abbrechen
            </button>
          </div>
        </form>
      </section>
    );
  }
}
