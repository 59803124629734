import React, { Component, ReactElement } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Api } from "../../api/Api";
import { Filled, Bordered } from "../../components/Divs.component";
import { PlayerLinkWithID } from "../../components/PlayerLink.component";
import { PlayerMinimal } from "../player/Player.interfaces";

import { AllianceLinkWithID } from "../../components/AllianceLink.component";
import { AllianceWithMembers } from "./Alliance.interface";
import { DateSpan } from "../../components/DateSpan.component";

interface MatchParams {
  id: string | undefined;
}

export class AllianceDetail extends Component<
  RouteComponentProps<MatchParams>
> {
  state: {
    isLoaded: boolean;
    error: Error | null;
    allianceId: number;
    data: AllianceWithMembers | undefined;
  };

  constructor(props: RouteComponentProps<MatchParams>) {
    super(props);
    if (!this.props.match.params.id) {
      throw new Error("No id provided");
    }
    const allianceId = Number.parseInt(this.props.match.params.id);
    this.state = {
      isLoaded: false,
      error: null,
      allianceId: allianceId,
      data: undefined,
    };
  }

  async componentDidMount() {
    try {
      const data = await Api.getInstance().fetchAlliance(this.state.allianceId);
      this.setState({
        data: data,
        isLoaded: true,
        error: null,
      });
    } catch (error) {
      console.error(error);
      this.setState({
        data: undefined,
        isLoaded: false,
        error: error,
      });
    }
  }

  render(): ReactElement {
    if (!this.state.isLoaded && this.state.error === null) {
      return <h1>Loading...</h1>;
    }
    if (this.state.error) {
      return <h1>{this.state.error.toString()}</h1>;
    }
    if (!this.state.data) {
      return <h1>Could not retrieve data</h1>;
    }
    const alliance = this.state.data;
    return (
      <section className="flex flex-col justify-center mx-auto">
        <h1 className="px-2 m-2 mb-2 text-center bg-gray-800 border border-gray-800 border-solid">
          <AllianceLinkWithID alliance={alliance}></AllianceLinkWithID>
        </h1>
        <div className="flex flex-row flex-wrap max-w-full mx-2">
          {/* Allgemein */}
          <div className="max-w-sm mt-2 mr-2">
            <div className="grid grid-cols-2 gap-1">
              <div className="col-span-2 px-2 bg-gray-800 border border-gray-800 border-solid">
                Präsident
              </div>
              <div className="col-span-2 px-2 break-all border border-gray-800 border-solid">
                <PlayerLinkWithID player={alliance.president} />
              </div>
              <Filled>Mitgliederanzahl</Filled>
              <Bordered className="text-right">
                {alliance.members.length}
              </Bordered>
              <Filled>Aktualisiert</Filled>
              <Bordered className="text-right">
                <DateSpan date={alliance.updatedAt} />
              </Bordered>
              <Filled>Erstellt</Filled>
              <Bordered className="text-right">
                <DateSpan date={alliance.createdAt} />
              </Bordered>
            </div>
            {/* Allianz-Mitglieder auflisten */}
            <div className="grid max-w-sm grid-cols-1 gap-1 mt-2">
              <Filled>Mitglieder</Filled>
              {alliance.members.map((player: PlayerMinimal) => (
                <Bordered>
                  <PlayerLinkWithID player={player} />
                </Bordered>
              ))}
            </div>
          </div>
        </div>
      </section>
    );
  }
}
