import React, { Component, ReactElement } from "react";
import { RouteComponentProps } from "react-router-dom";
import { AllianceLinkWithID } from "../../components/AllianceLink.component";
import { ScanObject } from "../scan/Scan.interfaces";
import { Player, PlayerHistory } from "./Player.interfaces";
import { PlayerLinkWithID } from "../../components/PlayerLink.component";
import { Api } from "../../api/Api";
import { Bordered, Filled } from "../../components/Divs.component";
import { PlayerHistoryList } from "./PlayerHistoryList.component";
import { ColoniesList } from "../../components/ColoniesList.component";
import { ShipsList } from "../../components/ShipsList.component";
import { DateSpan } from "../../components/DateSpan.component";

interface MatchParams {
  id: string | undefined;
}

export interface PlayerDetailData {
  player: Player;
  histories: PlayerHistory[];
  colonies: ScanObject[];
  ships: ScanObject[];
}

export class PlayerDetail extends Component<RouteComponentProps<MatchParams>> {
  state: {
    isLoaded: boolean;
    error: Error | null;
    playerId: number;
    data: PlayerDetailData | undefined;
  };

  constructor(props: RouteComponentProps<MatchParams>) {
    super(props);
    if (!this.props.match.params.id) {
      throw new Error("No id provided");
    }
    const playerId = Number.parseInt(this.props.match.params.id);
    this.state = {
      isLoaded: false,
      error: null,
      playerId: playerId,
      data: undefined,
    };
  }

  async componentDidMount() {
    try {
      const data = await Api.getInstance().fetchPlayer(this.state.playerId);
      this.setState({
        data: data,
        isLoaded: true,
        error: null,
      });
    } catch (error) {
      console.error(error);
      this.setState({
        data: undefined,
        isLoaded: false,
        error: error,
      });
    }
  }

  render(): ReactElement {
    if (!this.state.isLoaded && this.state.error === null) {
      return <h1>Loading...</h1>;
    }
    if (this.state.error) {
      return <h1>{this.state.error.toString()}</h1>;
    }
    if (!this.state.data) {
      return <h1>Could not retrieve data</h1>;
    }
    const data = this.state.data;

    return (
      <section className="flex flex-col justify-center mx-auto">
        <h1 className="px-2 m-2 mb-2 text-center bg-gray-800 border border-gray-800 border-solid">
          <PlayerLinkWithID player={data.player}></PlayerLinkWithID>
        </h1>
        <div className="flex flex-row flex-wrap max-w-full mx-2">
          {/* Allgemein */}
          <div className="max-w-xs mt-2 mr-2">
            <h2 className="w-full mb-2 text-center bg-gray-800 border border-gray-800 border-solid">
              Allgemein
            </h2>
            <div className="grid grid-cols-2 gap-1">
              <Filled>Ansehen</Filled>
              <Bordered className="text-right">
                {data.player.reputation}
              </Bordered>
              <Filled>Level</Filled>
              <Bordered className="text-right">{data.player.level}</Bordered>
              <Filled>Runden</Filled>
              <Bordered className="text-right">{data.player.ticks}</Bordered>
              <div className="col-span-2 px-2 bg-gray-800 border border-gray-800 border-solid">
                Allianz
              </div>
              <div className="col-span-2 px-2 break-all border border-gray-800 border-solid">
                {data.player.alliance ? (
                  <AllianceLinkWithID alliance={data.player.alliance} />
                ) : (
                  "-"
                )}
              </div>
              <Filled>Kolonien</Filled>
              <Bordered className="text-right">{data.colonies.length}</Bordered>
              <Filled>Raumschiffe</Filled>
              <Bordered className="text-right">{data.ships.length}</Bordered>
              <Filled>Aktualisiert</Filled>
              <Bordered className="text-right">
                <DateSpan date={data.player.updatedAt} />
              </Bordered>
              <Filled>Erstellt</Filled>
              <Bordered className="text-right">
                <DateSpan date={data.player.createdAt} />
              </Bordered>
              <div className="col-span-2 px-2 bg-gray-800 border border-gray-800 border-solid">
                Punkte:
              </div>
              <div className="grid grid-cols-2 col-span-2 gap-1 p-1 border border-gray-800 border-solid">
                <Filled>Wissenschaft</Filled>
                <Bordered className="text-right">
                  {data.player.points.research}
                </Bordered>
                <Filled>Geselschaft</Filled>
                <Bordered className="text-right">
                  {data.player.points.society}
                </Bordered>
                <Filled>Wirtschaft</Filled>
                <Bordered className="text-right">
                  {data.player.points.economy}
                </Bordered>
                <Filled>Militär</Filled>
                <Bordered className="text-right">
                  {data.player.points.military}
                </Bordered>
              </div>
            </div>
          </div>
          {/* Kolonien */}
          <div className="mt-2 mr-2">
            <h2 className="w-full mb-2 text-center bg-gray-800 border border-gray-800 border-solid">
              Kolonien
            </h2>
            <div className="">
              <ColoniesList colonies={data.colonies} />
            </div>
          </div>
          {/* Schiffe */}
          <div className="flex-grow mt-2">
            <h2 className="w-full px-2 mb-2 text-center bg-gray-800 border border-gray-800 border-solid">
              Schiffe
            </h2>
            <div className="mx-auto">
              <ShipsList ships={data.ships} />
            </div>
          </div>
          {/* Versionierung */}
          <div className="flex-grow mt-2">
            <h2 className="w-full px-2 mb-2 text-center bg-gray-800 border border-gray-800 border-solid">
              Versionierung:
            </h2>
            <div className="mx-auto">
              <PlayerHistoryList
                player={data.player}
                histories={data.histories}
              />
            </div>
          </div>
        </div>
      </section>
    );
  }
}
