export class DateHelper {
  date: Date;

  constructor(date: Date) {
    this.date = date;
  }

  public static getDateRepresentation(dateParam: Date) {
    const dh = new DateHelper(new Date(dateParam));
    return `${dh.getDay()}.${dh.getMonth()}.${dh.getYear()}`;
  }

  public static getDateTimeRepresentation(dateParam: Date) {
    const dh = new DateHelper(new Date(dateParam));
    return `${dh.getHours()}:${dh.getMinutes()} ${dh.getDay()}.${dh.getMonth()}.${dh.getYear()}`;
  }

  private getHours(): string {
    return "" + this.pad(this.date.getHours());
  }

  private getMinutes(): string {
    return "" + this.pad(this.date.getMinutes());
  }

  private getDay(): string {
    return "" + this.pad(this.date.getDate());
  }

  private getMonth(): string {
    return "" + this.pad(this.date.getMonth() + 1);
  }
  private getYear(): string {
    return "" + this.date.getFullYear();
  }
  private pad(num: number) {
    var s = num + "";
    while (s.length < 2) s = "0" + s;
    return s;
  }
}
